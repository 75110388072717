import {Component, Inject, OnInit} from '@angular/core';
import {MessangerConnectionService} from "./messanger-connection.service";
import {FingerPrint} from "./fingerprint.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface MyfingerprintComponentData {
  nick: string;
  phone: string;
  fingerprint: string;
  avatarSrc: string | undefined;
}


@Component({
  selector: 'app-myfingerprint',
  templateUrl: './myfingerprint.component.html',
  styleUrls: ['./myfingerprint.component.css']
})
export class MyfingerprintComponent implements OnInit {

  nick: string = "";
  phone: string = "";
  avatarSrc: string = "/download/avatars/user-profile.jpeg";

  fingerprint1: string = "";
  fingerprint2: string = "";
  fingerprint3: string = "";
  fingerprint4: string = "";


  emoji1: string = "";
  emoji2: string = "";
  emoji3: string = "";
  emoji4: string = "";

  constructor(public connection: MessangerConnectionService,
  @Inject(MAT_DIALOG_DATA) public data: MyfingerprintComponentData

  ) {

  }

  ngOnInit(): void {
    if(this.data.avatarSrc != undefined) {
      this.avatarSrc = this.data.avatarSrc;
    }
    this.phone = this.connection.formatPhone(this.data.phone);
    this.nick = this.data.nick;
    let fingerprint = this.data.fingerprint;
    let fp = new FingerPrint();
    let ret = fp.fingerprintArrayStr(fingerprint)
    this.fingerprint1 = ret[0];
    this.fingerprint2 = ret[1];
    this.fingerprint3 = ret[2];
    this.fingerprint4 = ret[3];
    let retEmoji = fp.fingerprintArrayCodes(fingerprint);
    this.emoji1 = retEmoji[0];
    this.emoji2 = retEmoji[1];
    this.emoji3 = retEmoji[2];
    this.emoji4 = retEmoji[3];
  }

}
