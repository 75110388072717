import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {MessangerConnectionService} from './messanger-connection.service';
import {Router} from '@angular/router';
import {Contact, Conversation, SessionKey} from './model';
import {AddAdmin, AddMember, CreateConversation, GetContactList, GetUserInfo, SessionKeyValue} from "./request";
import {ContactList, CreatedConversation, StatusMessage, UserInfo} from "./response";
import {AbstractContact} from "./contact";

@Component({
  selector: 'app-create-conversation',
  templateUrl: './create-conversation.component.html',
  styleUrls: ['./create-conversation.component.css']
})


export class CreateConversationComponent extends AbstractContact implements OnInit {

  formDialog = new FormGroup({
    userId: new FormControl()
  });

  formConversation: FormGroup;

//  contacts: Contact[] = [];
  selectedUsers: string[] = [];
  hidden: boolean = true;

  constructor(private router: Router, private connection: MessangerConnectionService, private fb: FormBuilder) {
    super();
    let formControlObjectEmpty:IDictionary = {} as IDictionary;
    formControlObjectEmpty['title'] = new FormControl("",[Validators.required, Validators.minLength(3)]);
    this.formConversation = fb.group(formControlObjectEmpty);
    if(this.connection.encryption!=null) {
      this.loadContacts(() => {
        this.contacts.forEach(c => this.formConversation.addControl(c.contactId, new FormControl(false)));
        this.hidden = false;
      });

    }
  }

  getWebsocket(): MessangerConnectionService {
    return this.connection;
  }

  selectCheckBox() {
    let formFields = this.formConversation.value;
    this.selectedUsers = Object.keys(formFields).filter(key => key != 'title').filter(key => formFields[key]);

  }

  create(isDialog: boolean) {
    let title = null;
    if (isDialog == false) {
      title = this.formConversation.value["title"];
    }

    let sessionKeyValue: SessionKeyValue = this.connection.encryption.generateSessionKeyValue(this.connection.userId,this.connection.user.keyPairUuid);
    let requestCreateConversation = new CreateConversation(isDialog, sessionKeyValue, title);


    this.connection.sendRequest(requestCreateConversation, (responseObject) => {
      let responseCreatedConversation = responseObject as CreatedConversation

      if (isDialog == true) {
        this.addMember([(this.formDialog.get("userId") as AbstractControl).value], true, responseCreatedConversation.conversation, () => {
          this.router.navigate(['/messenger']);
        });
      } else {

        this.addMember(this.selectedUsers, false, responseCreatedConversation.conversation, () => {
          this.router.navigate(['/messenger']);
        });
      }


    });


  }


  ngOnInit() {

    if (this.connection.encryption == null) {
      this.router.navigate(['/']);
    } else {

    }
  }
}


interface IDictionary {
  [index: string]: FormControl;
}
