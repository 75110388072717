import {MessangerConnectionService} from "./messanger-connection.service";
import {Contact, Conversation} from "./model";
import {AddAdmin, AddMember, GetContactList, SessionKeyValue} from "./request";
import {ContactList, StatusMessage} from "./response";
import {FormControl} from "@angular/forms";
import {Router} from "@angular/router";



export abstract class AbstractContact {

  contacts: Contact[] = [];

  abstract getWebsocket(): MessangerConnectionService

  loadContacts(next:() => void) {
    let formControlObject: IDictionary = {} as IDictionary;
    let contacts: Contact[] = [];
    let contactsModel = this.contacts;
    let requestGetContactList = new GetContactList(this.getWebsocket().userId);
    let encryption = this.getWebsocket().encryption;
    this.getWebsocket().sendRequest(requestGetContactList, (responseContactList) => {
      function fillContactList(users: Contact[]) {
        if(users.length == 0) {
          next();
        } else {
          let user =  users.pop() as Contact;
          let userId = user.contactId;
          if(userId == undefined) {
            formControlObject[userId] = new FormControl(false);
            contacts.forEach(c=> {
              contactsModel.push(c);
            });
            next();
            return;
          }

          formControlObject[userId] = new FormControl(false);
          contacts.push(new Contact(userId, user.contactIdEncrypted, user.nickEncrypted, user.trustedFingerprintEncrypted));
          if (users.length > 0) {
            fillContactList(users);
          } else {
            contacts.forEach(c=> {
              contactsModel.push(c);
            });
            next();
          }

          // encryption.getNick(userId, (nick) => {
          //   contacts.push(new Contact(userId, nick, "",""));
          //   if (users.length > 0) {
          //     fillContactList(users);
          //   } else {
          //     contacts.forEach(c=> {
          //       contactsModel.push(c);
          //     });
          //     next();
          //   }
          //
          // })
        }
      }
      fillContactList((responseContactList as ContactList).users);
    });
  }

  addMember(selectedUsers: string[], admin: boolean, conversation: Conversation, next: () => void) {

    // this.getWebsocket().encryption.sessionKeyForUser(conversation.uuid, conversation.sessionKeyUuid,
    //   selectedUsers.pop(), (userSessionKey: SessionKeyValue) => {
    //     let requestAddAdminOrMember = null;
    //     if (admin) {
    //       requestAddAdminOrMember = new AddAdmin(conversation.uuid, conversation.sessionKeyUuid, userSessionKey);
    //     } else {
    //       requestAddAdminOrMember = new AddMember(conversation.uuid, conversation.sessionKeyUuid, userSessionKey);
    //     }
    //     this.getWebsocket().sendRequest(requestAddAdminOrMember, (respnseStatusMessage: StatusMessage) => {
    //
    //
    //       if (selectedUsers.length == 0) {
    //         next();
    //       } else {
    //         this.addMember(selectedUsers, admin, conversation, next);
    //       }
    //
    //     });
    //
    //   });

  }

}


interface IDictionary {
  [index: string]: FormControl;
}
