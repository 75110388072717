<mat-sidenav-container>
  <mat-sidenav>
    Sidenav content
  </mat-sidenav>

  <mat-sidenav-content>

    <app-nav></app-nav>

    <mat-card style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.43);">

        <div [hidden]="showProgress" style="padding: 10px 5px 5px 5px;">
          <span style="padding: 0px 0px 0px 0px;font-family: Roboto;font-size: 20px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #4a4a4a;">Sign up</span>

          <form [formGroup]="userRegistration"  style="padding: 20px 0px 0px 0px;">


            <p>
              <mat-form-field appearance="standard" style="width: 280px;">
                <mat-label>Create nickname or enter full name</mat-label>
                <input matInput #input minlength="3" maxlength="128" [autofocus]="true" [formControl]="nick">
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="standard" style="width: 280px;">
                <mat-label>Create password</mat-label>
                <input matInput #input  [type]="hide ? 'password' : 'text'"   [formControl]="password" (keydown)="registrationKeys($event)">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint>At least 8 symbols</mat-hint>
              </mat-form-field>
            </p>

            <p>
              <mat-form-field appearance="standard" style="width: 280px;">
                <mat-label>Confirm password</mat-label>
                <input matInput #input     [type]="hide ? 'password' : 'text'"   [formControl]="passwordR" (keydown)="registrationKeys($event)">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <mat-checkbox [formControl]="eula">Accept <a (click)="eulaShow()" style="text-decoration: underline;">EULA</a></mat-checkbox>
            </p>


        </form>
        <div style="text-align: right;">
          <a  (click)="back()" mat-button style="width: 104px;align-items: flex-end; margin-right: 10px">< Back </a>
          <button (click)="registration()" [disabled]="eula.value == false||!nick.valid || !password.valid || !(password.value == passwordR.value)"  mat-flat-button mat-button color="primary"  style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}}; width: 104px;align-items: flex-end; " >Sign up</button>
        </div>


        </div>
        <div [hidden]="!showProgress">
          <p>Registration in progress... Keys generation... It may take 2-3 minutes... Please wait...</p>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>

        </div>

      </mat-card>

  </mat-sidenav-content>
</mat-sidenav-container>
