import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {Request, AuthenticationByApplication, GetUserInfo, GetServerSettings} from './request';
import {Response, StatusMessage, ServerSettings, UserInfo} from './response';
import { Encryption} from './encryption';
import { MessangerConnectionService } from './messanger-connection.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginGroup: FormGroup;

  password = new FormControl('',[ additinalValidator(this)]);

  userIdOpen: string = "";
  passwordErrorHide = true;
  hide: boolean = true;
  forgetHidden: boolean = true;


  constructor(private router: Router, public con: MessangerConnectionService, fb: FormBuilder) {

  this.loginGroup = fb.group({
    password: this.password
  });
  this.userIdOpen = con.userIdOpen;

  }

  login () {
    if(this.con.user.privateKeyEncryptedBase64 != "") {
      this.loginProccess();
    } else {
      let requestGetUserInfo = new GetUserInfo(this.con.userId);
      this.con.sendRequest(requestGetUserInfo,
        (responseUserInfo) => {
          this.con.user = (responseUserInfo as UserInfo).user;
          this.loginProccess();
        }
      );
    }
  }

  logout () {
    this.con.clear();
    window.location.href = '/';

  }

  private loginProccess() {
    let encryption = new Encryption(this.con);
    let loginned = false
    try {
      loginned = encryption.restoreFrom(this.con.user,this.password.value);
    } catch (e) {
    }
    // console.log("fingerprint "+ encryption.fingerprint());
    if(loginned) {
      this.con.encryption = encryption;
      if(this.con.user.nick != null) {
        this.con.nickOpen = this.con.encryption.decryptByMetaDataKey(this.con.user.nick);
      }
      this.router.navigate(['/messenger']);


    } else {
      this.passwordErrorHide = false;
      this.password.setValue('');
      this.password.updateValueAndValidity();
      this.forgetHidden = false;
      setTimeout(()=> {
        this.passwordErrorHide = true;
      },2000)
    }
  }

  ngOnInit() {

    if(this.con.appUuid == null) {
      this.router.navigate(['/']);
    } else {
      this.con.authorizeApp(() => {}, (err)=>{
        alert(err);
      });

    }


  }

  loginKeys(event: any) {
    if (event.keyCode == 13) {
      this.login();
    }
  }

  back() {
    this.con.clear();
    window.location.href="/";
  }

  formatPhone(str: String) {
    return this.con.formatPhone(str);
  }

}

export function additinalValidator(trigger: LoginComponent): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!trigger.passwordErrorHide) {
      return {additinal: {valid: false}} ;
    } else {
      return null;
    }
  };
}
