<mat-sidenav-container>
  <mat-sidenav>
    Sidenav content
  </mat-sidenav>

  <mat-sidenav-content>

    <app-nav></app-nav>

    <mat-card style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.43);z-index: 999999;">

      <div style="padding: 10px 5px 5px 5px;">
        <span style="padding: 0px 0px 0px 0px;font-family: Roboto;font-size: 20px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #4a4a4a;">Sign in with<br> {{formatPhone(userIdOpen)}}</span>

        <form [formGroup]="loginGroup"  style="padding: 20px 0px 0px 0px;">


          <p>
            <mat-form-field appearance="standard" style="width: 280px;">
              <mat-label>Password</mat-label>

              <input matInput #input  type="password"  [autofocus]="true" [formControl]="password" (keydown)="loginKeys($event)">

<!--              <input matInput #input  [type]="hide ? 'password' : 'text'"  [autofocus] [formControl]="password" (keydown)="loginKeys($event)">-->
<!--              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">-->
<!--                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>-->
<!--              </button>-->
              <mat-error *ngIf="!passwordErrorHide">
                Incorrect password
              </mat-error>
            </mat-form-field>
          </p>


        </form>
        <div style="text-align: right;">
<!--          <a  (click)="back()" mat-button style="width: 104px;align-items: flex-end; margin-right: 10px">< Back </a>-->
          <a  (click)="back()" mat-button style="align-items: flex-end; margin-right: 10px">< Back </a>
<!--          <a  routerLink="/forget" mat-stroked-button style="align-items: flex-end; margin-right: 10px" *ngIf="!forgetHidden">Forget password </a>-->
          <button (click)="login()"  mat-flat-button mat-button color="primary"  style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}}; width: 104px;align-items: flex-end; ">Sign in</button>
        </div>

      </div>

    </mat-card>



  </mat-sidenav-content>
</mat-sidenav-container>
