import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {Contact, Conversation, Message} from "./model";
import {
  AddMessage,
  DeleteConversation,
  GetConversations,
  GetMembersList,
  LeaveConversation,
  RenameConversation
} from "./request";
import {MessangerConnectionService} from "./messanger-connection.service";
import {AddedMessage, Conversations, MembersList} from "./response";
import {AbstractConversation} from "./conversation";
import { faMinusCircle, faPaperPlane, faImages, faEdit, faPowerOff, faMale, faUserPlus, faKey, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AddParticipantComponent} from "./add-participant.component";


@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent extends AbstractConversation  implements OnInit {

  faEdit = faEdit;

  title = new FormControl('');
  hideTitleEditor:boolean = true ;



  // @ts-ignore
  constructor(public route: ActivatedRoute, public router: Router, public websocket: MessangerConnectionService,
              public dialogRef: MatDialogRef<InformationComponent>, public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: InformationComponentData
              ) {
    super(null, router,  websocket, data.conversationUuid);
  }

  ngOnInit() {
    this.loadConversation(() => {
      this.title.setValue(this.conversation.title);

      let conversationUserId = ""
      if(this.conversation.isDialog) {
        conversationUserId = this.admins.filter(u => u.contactId != this.websocket.userId)[0].contactId;
      }
      this.websocket.downloadAvatarForConversation(this.conversation, conversationUserId,  avatar => {
        this.conversation.image = avatar;
        this.admins.forEach(admin => {
          this.websocket.downloadAvatarForConversation(this.conversation, admin.contactId,  avatar => {
            admin.avatarSrc = avatar;
          })
        });

        this.members.forEach(member => {
          this.websocket.downloadAvatarForConversation(this.conversation, member.contactId,  avatar => {
            member.avatarSrc = avatar;
          })
        });

      })

    });
  }


  updateTitle() {
    this.hideTitleEditor = false;
  }

  updateNickSave() {
    this.websocket.encryption.encryptBase64BySessionKey(this.title.value, this.conversation.sessionKeyUuid, this.conversation.uuid, (encTitle:string) => {
      let renameConversation = new RenameConversation(this.conversation.uuid, encTitle);

      this.websocket.sendRequest(renameConversation, (ok) => {
        // this.websocket.sendMessage("Rename conversation to: "+ this.title.value, this.conversation.uuid, this.conversation.sessionKeyUuid, () => {});
        this.conversation.title = this.title.value;
        this.hideTitleEditor = true;
      });

    })
  }

  addParticipant() {

    this.dialogRef.close();
    const dialogRef = this.dialog.open(AddParticipantComponent, {
      width: '450px',
      data: {conversationUuid: this.data.conversationUuid}
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }
}

export interface  InformationComponentData {
  conversationUuid: string;
}
