import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Request, Ping } from './request';
import { Response, StatusMessage } from './response';
import { MessangerConnectionService } from './messanger-connection.service';
import { User } from "./model";
import { FormControl } from '@angular/forms';




@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private connection: MessangerConnectionService) {
    console.log("HomeComponent constructor")
    connection.sendRequest(new Ping(new Date()),
      (responseObject) => {
        // console.log(responseObject);
      }

    );
    if (connection.appUuid != null) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/registration-app']);
    }
  }

  ngOnInit() {
  }

}
