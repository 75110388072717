<h1 mat-dialog-title></h1>
<div mat-dialog-content>
  <img *ngIf="getFileType(file.fileName) == 'jpeg'"  [src]="file.body"  width="850" />


<!--  <video *ngIf="getFileType(file.fileName) != 'jpeg'" width="900" controls>-->
<!--    <source [src]="file.body" type="video/3gp">-->
<!--  </video>-->


  <span  *ngIf="getFileType(file.fileName) == '3gp'">{{file.fileName}} </span><fa-icon *ngIf="getFileType(file.fileName) == '3gp'" [icon]="faFileDownload"
           (click)="downloadFile(file)"></fa-icon>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Close</button>
</div>
