import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBarRef} from "@angular/material/snack-bar";




@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css']
})
export class WarningComponent implements OnInit{

  constructor(
    public snackBar:MatSnackBarRef<WarningComponent>

  ) { }


  ngOnInit(): void {
  }
}
