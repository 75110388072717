import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface VerificationData {
  contactId: string;
  contactIdOpen:  string;
  nickOpen:  string;

}


@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<VerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VerificationData

  ) { }

  onLaterClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
