
<div mat-dialog-content>
  <div  style="text-align: center;">
    <img src="{{conversation.image}}"  style="border-radius: 50%;" width="200" height="200">
    <h1 [hidden]="!hideTitleEditor" style="font-family: Roboto;font-size: 16px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.5;letter-spacing: normal;" >{{conversation.title}}
      <fa-icon [icon]="faEdit" [hidden]="!isAdmin || conversation.isDialog" ngbTooltip="Edit title" (click)="updateTitle()" ></fa-icon>
    </h1>
    <h1 [hidden]="hideTitleEditor">
      <input  [formControl]="title" placeholder="Title" type="text">
      <button  (click)="updateNickSave()" class="btn btn-primary">Save</button>
      <button  (click)="hideTitleEditor=true" class="btn btn-danger">Cancel</button>
    </h1>

  </div>

    <span style="font-family: Roboto;font-size: 14px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal;color: rgba(0, 0, 0, 0.54);">{{admins.length + members.length}}&nbsp;participants:</span>
  <a *ngIf="!conversation.isDialog && isAdmin" (click)="addParticipant()"><span style="cursor: pointer ;font-family: Roboto;font-size: 14px;font-weight: 500;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal;text-align: right;color: #2e7d32;float:right;">+ Add participant</span></a>
    <br>
    <br>
    <p *ngFor="let admin of admins">
      <img src="{{admin.avatarSrc}}" style="border-radius: 50%;" width="40" height="40">
      &nbsp;&nbsp;&nbsp;<span style="position: relative; top: -13px; ">{{admin.nickOpen}} {{admin.contactIdOpen}}</span> <span style="position: relative; top: 13px;font-family: Roboto;font-size: 14px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal; color: rgba(95, 95, 185, 0.99); float:right;" *ngIf="!conversation.isDialog">Group admin</span>
    </p>
    <p *ngFor="let admin of members">
      <img src="{{admin.avatarSrc}}" style="border-radius: 50%;" width="40" height="40">
      &nbsp;&nbsp;&nbsp;<span style="position: relative; top: -13px; ">{{admin.nickOpen}} {{admin.contactIdOpen}}</span>
    </p>

</div>

<mat-dialog-actions align="end">
  <a [mat-dialog-close]="true" style="color: #2e7d32;">Close</a>
</mat-dialog-actions>
