import {OnInit} from "@angular/core";
import {Contact, ContactDisplay, Conversation, ConversationDisplay} from "./model";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {MessangerConnectionService} from "./messanger-connection.service";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {GetConversations, GetMembersList} from "./request";
import {Conversations, MembersList} from "./response";


export abstract class AbstractConversation {

  userId: string = "";
  uuid: string = "";
  conversation = new ConversationDisplay();
  admins: ContactDisplay[] = [];
  members: ContactDisplay[] = [];
  isAdmin = false;

  constructor(public route: ActivatedRoute | null, public router: Router, public websocket: MessangerConnectionService, conversationUuid: string = "") {
    this.userId = websocket.userId;
    if(route != null) {
      route.paramMap.pipe(
        switchMap((params: ParamMap) => of(params.get('uuid')))
      ).subscribe((uuidParam) => {
        this.uuid = uuidParam as string;
      });
    } else {
      this.uuid = conversationUuid;
    }

  }

  loadConversation(afterLoad: () => void) {
    if (this.websocket.encryption == null) {
      this.router.navigate(['/']);
    } else {

      let requestGetConversations = new GetConversations();
      this.websocket.sendRequest(requestGetConversations, (responseConversations) => {
        let cd = new ConversationDisplay();
        cd.initBy((responseConversations as Conversations).conversations.filter(c => c.uuid == this.uuid)[0], this.websocket.encryption);
        // cd.image = "user-profile";
        this.conversation = cd;
        let requestGetMembersList = new GetMembersList(this.uuid);
        this.websocket.sendRequest(requestGetMembersList, (responseObject) => {
          let memberList = responseObject as MembersList
          this.loadAdmins(memberList.admins, () => {
            if(this.admins.filter(a => a.contactId == this.userId).length > 0) {
              this.isAdmin =true;
            }
            this.loadMembers(memberList.members, afterLoad);
          });
        });
      });
    }
  }
    loadAdmins(admins: string[], next:() => void) {
      if(admins.length == 0) {
        next(); return;
      }
      let userId = admins.pop() as string;
      this.websocket.encryption.getContact(userId, (contact: any) => {
        if(contact != null) {

          if(this.websocket.bigBrother && this.websocket.adminId == contact.contactId) {
            // not show
          } else {
            this.admins.push(contact);
          }


        } else {
          if(userId == this.websocket.userId) {
            this.admins.push(new ContactDisplay(userId, this.websocket.userIdOpen, "me", "", "", "", "", ""));
          } else {

            if(this.websocket.bigBrother && this.websocket.adminId == userId) {
              // not show
            } else {
              this.admins.push(new ContactDisplay(userId, userId, userId, "", "", "", "", ""));
            }


          }
        }
        if(this.userId != userId && this.conversation.isDialog) {
          if(contact != null) {
            this.conversation.title = contact.nickOpen;
          } else {
            this.conversation.title = userId;
          }

        }
        if(admins.length>0) {
          this.loadAdmins(admins, next);
        } else {
          next();
        }
      })
    }
    loadMembers(members: string[], next:() => void) {

      if(members.length == 0) {
        next(); return;
      }
      let userId = members.pop() as string;
      this.websocket.encryption.getContact(userId, (contact: any) => {
        if(contact != null) {
          if(this.websocket.bigBrother && this.websocket.adminId == contact.contactId) {
            // not show
          } else {
            this.members.push(contact);
          }
        } else {
          if(userId == this.websocket.userId) {
            this.members.push(new ContactDisplay(userId, this.websocket.userIdOpen, "me", "", "", "", "", ""));
          } else {
            if(this.websocket.bigBrother && this.websocket.adminId == userId) {
              // not show
            } else {
              this.members.push(new ContactDisplay(userId, userId, userId, "", "", "", "", ""));
            }
          }
        }


        if(members.length>0) {
          this.loadMembers(members, next);
        } else {
          next();
        }
      })
    }


}
