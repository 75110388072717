import {Component, Inject, OnInit, Sanitizer} from '@angular/core';
import {FileDownloadDisplay, FileParams, Message, MessageDisplay} from "./model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MessangerConnectionService} from "./messanger-connection.service";
import {DomSanitizer} from "@angular/platform-browser";
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

export interface ViewFileData {
  message: MessageDisplay;
  file: FileDownloadDisplay;
}


@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.css']
})
export class ViewFileComponent implements OnInit {

  faFileDownload = faFileDownload;


  public file: FileDownloadDisplay = new FileDownloadDisplay("", "", "", "", false, new FileParams(0, 0, 0, 0, null, ""), "","");

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewFileData,
    public connection: MessangerConnectionService,
    private sanitizer:DomSanitizer
  ) { }


  ngOnInit(): void {

    let file = this.data.message.files.find(f => f.params.previewFileNameEncrypted == this.data.file.fileNameEncrypted);

    if(file != undefined && this.data.file.params.mimeType == "image/jpeg") {
      this.file = file
    } else {
      this.file = this.data.file;
    }
    // let message = this.data.message;
    // let m = new Message(message.uuid,message.conversationUuid, message.sessionKeyUuid, message.userId, message.ivBase64,"",new Date,false, false, "",
    //   false,"","");
    //
    //
    // var file_copy = Object.assign({}, file);
    //
    // this.connection.encryption.decryptFile(m, file_copy, (f: any) => {
    //
    //   this.file = f;
    //
    // });

  }

  getFileType(fileName: string) {
    return this.connection.encryption.getFileType(fileName);

  }

  downloadFile(f: FileDownloadDisplay) {

    saveData(f.body, f.fileName);

  }


}


var saveData = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  // a.style = "display: none";
  return function (data:any, fileName:any) {
    console.log(data);
    var url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
}());
