import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[scrolldown]'
})
export class ScrolldownDirective
{
  constructor(private el: ElementRef)
  {
  }

  ngOnInit()
  {
    let number = this.el.nativeElement.scrollHeight;
    this.el.nativeElement.scrollTop = number;
  }

  @Input() set scrolldown(condition: boolean)
  {
    this.ngOnInit();
  }
}
