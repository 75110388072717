import {Component, OnInit} from '@angular/core';
import {AbstractContact} from "./contact";
import {MessangerConnectionService} from "./messanger-connection.service";
import {GetContactList, GetRegisteredUsers, GetUserInfo, UpdateContact, UpdateContactList} from "./request";
import {ContactList, RegisteredUsers, StatusMessage, UserInfo} from "./response";
import {Contact, ContactDisplay} from "./model";
import {MyfingerprintComponent} from "./myfingerprint.component";
import {MatDialog} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  contacts: ContactDisplay[] = [];

  contactForm: FormGroup;
  phone = new FormControl('');
  nick = new FormControl('');

  addContactShow: boolean = false;


  constructor(public con: MessangerConnectionService,
              public dialog: MatDialog, private fb: FormBuilder) {

    this.contactForm = new FormGroup({
      phone: this.phone,
      nick: this.nick
    });

  }

  ngOnInit(): void {
    this.loadContacts();
  }

  loadContacts() {
    let requestGetContactList = new GetContactList(this.con.userId);
    this.con.sendRequest(requestGetContactList, (responseContactList) => {
      this.contacts = (responseContactList as ContactList).users.filter(c => {
        if (this.con.bigBrother && this.con.adminId == c.contactId) {
          return false;
        } else {
          return true;
        }
      }).map(c => {
        let cd = new ContactDisplay("", "", "", "", "", "", null, "");
        cd.initBy(c, this.con.encryption);
        cd.avatarSrc = "/download/avatars/user-profile.jpeg"
        return cd;
      })
    });
  }

  showFingerprint(cd: ContactDisplay) {

    let requestGetUserInfo = new GetUserInfo(cd.contactId)
    this.con.sendRequest(requestGetUserInfo,
      (responseUserInfo) => {
        const fingerprintComponent = this.dialog.open(MyfingerprintComponent, {
          width: '600px',
          data: {
            nick: cd.nickOpen,
            phone: cd.contactIdOpen,
            fingerprint: this.con.encryption.fingerprintByPublicKey((responseUserInfo as UserInfo).user.publicKeyBase64),
            avatarSrc: cd.avatarSrc
          }
        });
        fingerprintComponent.afterClosed().subscribe(result => {
        });
      }
    );
  }

  addContact() {
    this.addContactShow = true;
  }

  deleteContact(contact: Contact) {
    let updatedContacts = this.contacts.filter(c => c.contactId != contact.contactId);
    let requestUpdate = new UpdateContactList(updatedContacts.map(c => c.contactId));
    this.con.sendRequest(requestUpdate, (status) => {
      this.contacts = updatedContacts;
    })
  }


  addContactSave() {
    this.addContactShow = false;
    let requestGetUserInfo = new GetUserInfo(this.con.encryption.sha256(this.phone.value));
    this.con.sendRequest(requestGetUserInfo,
      (responseObject) => {
        let info = responseObject as UserInfo
        // if (info.user.publicKeyBase64 != null){
          if (info.user.publicKeyBase64 != null){
          let contactId = this.con.encryption.sha256(this.phone.value);
          let nickEncrypted = this.con.encryption.encryptByMetaDataKey(this.nick.value);
          let contactIdEncrypted = this.con.encryption.encryptByMetaDataKey(this.phone.value);
          let c = new Contact(contactId, contactIdEncrypted, nickEncrypted, null);
          let requestUpdateContact = new UpdateContact(this.con.userId, c);
          this.con.sendRequest(requestUpdateContact,
            (status) => {
              this.loadContacts();
            });
        } else {
          alert("User with this phone number is not registered");
        }
      });

  }

  showHint = false;
  change() {
    setTimeout(()=> {
      this.showHint = false;
      if(!this.phone.value.startsWith("+")) {
        this.showHint = true;
      }
      let end = this.phone.value.substring(1);
      let reg = /^\d+$/;
      if(!reg.test(end)) {
        this.showHint = true;
      }

    }, 200)
  }
}
