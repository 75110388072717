import {User, SessionKey, MessageDisplay, Message, FileDownload, FileDownloadDisplay, FileParams} from './model';
import {StorageService} from 'ngx-webstorage-service';


export class Storage {

  cache: Map<string, MessageDisplay> = new Map();

  constructor(private storage: StorageService) {

  }


  saveAppUuidAndUserId(appUuid: string, userId: string, userIdOpen: string) {
    this.storage.set("appUuid", appUuid);
    this.storage.set("userId", userId);
    this.storage.set("userIdOpen", userIdOpen);
  }


  saveMessage(message: MessageDisplay) {
    let messageEmptyBody = new MessageDisplay();
    Object.assign(messageEmptyBody, message);
    messageEmptyBody.files = [];
    if (message.files != null) {
      message.files.forEach(f => {
        let file = new FileDownloadDisplay("", "", "", "", true, new FileParams(0, 0, 0, 0, null, ""), "","");
        Object.assign(file, f);
        // file.body = "";
        // file.fileName = "";
        messageEmptyBody.files.push(file);
      });
    }
    messageEmptyBody.body = "";

    this.cache.set(message.uuid, message);
//      this.storage.set("message_" + messageEmptyBody.uuid, messageEmptyBody)
  }

  getMessage(uuid: string): MessageDisplay | null{
    return null;
    // if (this.cache.has(uuid)) {
    //   let ret =  this.cache.get(uuid);
    //   if(ret == undefined) {
    //     return {} as MessageDisplay
    //   } else {
    //     return ret as MessageDisplay
    //   }
    //
    // } else {
    //   return {} as MessageDisplay;
    // }


    // if(ret != null) {
    //   ret["created"] = new Date(ret["created"]);
    //   let clean = new MessageDisplay();
    //   Object.assign(clean, ret);
    //   clean.files = [];
    //   let files:[] = ret['files'];
    //   if(files != null) {
    //     files.forEach(f => {
    //       let file = new FileDownloadDisplay("", "", "", null);
    //       Object.assign(file, f);
    //       clean.files.push(file);
    //     });
    //   }
    //   return clean;
    // } else {
    //   return null;
    // }
  }

  getAppUuid(): string {
    return this.storage.get("appUuid") || null
  }

  getUserId(): string {
    return this.storage.get("userId") || null
  }
  getUserIdOpen(): string {
    return this.storage.get("userIdOpen") || null
  }

  getSessionKey(sessionKeyUuid: string): SessionKey {
    return this.storage.get("sessionKey_" + sessionKeyUuid) || null
  }

  saveSessionKey(sessionKey: SessionKey) {
    this.storage.set("sessionKey_" + sessionKey.uuid, sessionKey);
  }

  destroySessionKeys() {
    let appId = this.getAppUuid();
    let userId = this.getUserId();
    let userIdOpen = this.getUserIdOpen();
    this.storage.clear();
    this.saveAppUuidAndUserId(appId, userId, userIdOpen);
  }

  clear() {
    this.storage.clear();
  }
}
