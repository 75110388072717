<div class="m-3 p-3">

  <div class="card bg-light mb-3" style="max-width: 40em;">
    <div class="card-header">{{conversation.title}}</div>
    <div class="card-body">

      <form [formGroup]="formConversation"  (change)="clickCheckBox()">


        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nickname or full name</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let contact of contacts; let i = index;">
            <td>
              <input type="checkbox" [formControlName]="contact.contactId"> {{contact.contactId}}
            </td>
            <td>{{ contact.nickEncrypted }}</td>
          </tr>
          </tbody>
        </table>


        <button (click)="add(false)" class="btn btn-primary" [disabled]="selectedUsers.length==0">Invite like user</button>
        &nbsp;&nbsp;&nbsp;
        <button (click)="add(true)" class="btn btn-danger" [disabled]="selectedUsers.length==0" [hidden]="!isAdmin">Invite like admin</button>
      </form>

    </div>
  </div>
</div>
