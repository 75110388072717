import {Component, OnInit} from '@angular/core';
import {Contact, Conversation} from "./model";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {MessangerConnectionService} from "./messanger-connection.service";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {GetConversations, GetMembersList} from "./request";
import {Conversations, MembersList} from "./response";
import {AbstractConversation} from "./conversation";
import {AbstractContact} from "./contact";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})

export class InviteComponent extends AbstractConversation implements OnInit {

  contacts: Contact[] = [];
  formConversation: FormGroup ;
  contactLoader: ContactLoader = {} as ContactLoader;
  selectedUsers: string[] = [];

  // @ts-ignore
  constructor(public route: ActivatedRoute, public router: Router, public connection: MessangerConnectionService, private fb: FormBuilder) {
    super(route, router, connection);
    this.formConversation = fb.group([]);
    this.loadConversation(() => {
      this.contactLoader = new ContactLoader(this.connection);
      this.contactLoader.loadContacts(() => {
        this.contacts = this.contactLoader.contacts.filter(contact => {
          let users: Contact[] = this.members.concat(this.admins);
          if (users.filter(admin => admin.contactId == contact.contactId).length != 0) {
            return false;
          } else {
            return true;
          }
        });
        this.contacts.forEach(c => this.formConversation.addControl(c.contactId, new FormControl(false)));
      })

    });
  }

  ngOnInit() {


  }

  clickCheckBox() {
    let formFields = this.formConversation.value;
    this.selectedUsers = Object.keys(formFields).filter(key => key != 'title').filter(key => formFields[key]);
  }


  add(isAdmin: boolean) {


    this.contactLoader.addMember(this.selectedUsers, isAdmin, this.conversation, () => {
      this.router.navigate(['/messenger']);
    });


  }
}

export class ContactLoader extends AbstractContact {
  constructor(private websocket: MessangerConnectionService) {
    super();
  }

  getWebsocket(): MessangerConnectionService {
    return this.websocket;
  }

}
