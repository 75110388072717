import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  hide = true;

  constructor() { }

  ngOnInit(): void {

    setTimeout(()=> {
      this.hide = false;
    }, 500)
  }

}
