import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MessangerConnectionService} from "./messanger-connection.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {InformationComponentData} from "./information.component";
import {
  AddAdmin,
  AddMember,
  GetContactList,
  GetConversations,
  GetMembersList,
  RenameConversation,
  SessionKeyValue
} from "./request";
import {ContactList, Conversations, MembersList} from "./response";
import {ContactDisplay, Conversation, ConversationDisplay, SessionKey} from "./model";

@Component({
  selector: 'app-add-participant',
  templateUrl: './add-participant.component.html',
  styleUrls: ['./add-participant.component.css']
})
export class AddParticipantComponent implements OnInit {

  members: ContactDisplay[] = [];

  constructor(
    public websocket: MessangerConnectionService,
    public dialogRef: MatDialogRef<AddParticipantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddParticipantComponentData

  ) { }

  ngOnInit(): void {

    let requestGetMembersList = new GetMembersList(this.data.conversationUuid);
    this.websocket.sendRequest(requestGetMembersList, (responseObject) => {
      let memberList = responseObject as MembersList



      let requestGetContactList = new GetContactList(this.websocket.userId);
      this.websocket.sendRequest(requestGetContactList, (responseContactList) => {
        let contactsFull = (responseContactList as ContactList)
        let contacts = contactsFull.users.filter(contact => {
          if(memberList.members.find(el => el == contact.contactId) != undefined ||
            memberList.admins.find(el => el == contact.contactId) != undefined
          ) return false
          return true
        }).map(c => c.contactId)

        contacts.forEach(userId => {
          if(userId == this.websocket.supportId) {
            return;
          }
          this.websocket.encryption.getContact(userId, (contact: any) => {
            if (contact != null) {
              let conversation  = new ConversationDisplay();
              conversation.uuid = this.data.conversationUuid;
              this.websocket.downloadAvatarForConversation(conversation, contact.contactId,  avatar => {
                contact.avatarSrc = avatar;
              })

              this.members.push(contact);
            } else {
              if (userId == this.websocket.userId) {
                this.members.push(new ContactDisplay(userId, this.websocket.userIdOpen, "me", "", "", "", "", ""));
              } else {
                this.members.push(new ContactDisplay(userId, userId, userId, "", "", "", "", ""));
              }
            }
          })


        })



      });




    })

    }

  add() {

    let requestGetConversations = new GetConversations();
    this.websocket.sendRequest(requestGetConversations,
      (responseObject) => {
        let responseConversations = responseObject as Conversations
        let conversation = responseConversations.conversations.find(c => c.uuid == this.data.conversationUuid) as Conversation;
        let sessionKey = responseConversations.sessionKeys.find(s => s.conversationUuid == this.data.conversationUuid) as SessionKey;
        this.addMember(this.members.filter(m => m.checked ).map(m => m.contactId), false, conversation, sessionKey.signatureBase64, sessionKey.signerUserId,sessionKey.signerKeyPairUuid,() => {
          this.dialogRef.close();
        });
      });



  }

  addMember(selectedUsers: string[], admin: boolean, conversation: Conversation,signatureBase64: string, signerId: string, signerKeyPairUuid:string,  next: () => void) {

    this.websocket.encryption.sessionKeyForUser(conversation.uuid, conversation.sessionKeyUuid,
      selectedUsers.pop(), signatureBase64, signerId,signerKeyPairUuid ,(userSessionKey: SessionKeyValue) => {
        let requestAddAdminOrMember = null;
        if (admin) {
          requestAddAdminOrMember = new AddAdmin(conversation.uuid, conversation.sessionKeyUuid, userSessionKey);
        } else {
          requestAddAdminOrMember = new AddMember(conversation.uuid, conversation.sessionKeyUuid, userSessionKey);
        }
        this.websocket.sendRequest(requestAddAdminOrMember, (respnseStatusMessage) => {

          if (selectedUsers.length == 0) {
            next();
          } else {
            this.addMember(selectedUsers, admin, conversation, signatureBase64, signerId,signerKeyPairUuid,next);
          }

        });

      });

  }
}



export interface  AddParticipantComponentData {
  conversationUuid: string;
}

