import {Component, HostListener, OnInit, Sanitizer} from '@angular/core';
import {FormControl} from '@angular/forms';

import {MessangerConnectionService} from './messanger-connection.service';
import {ActivatedRoute, ParamMap, Route, Router} from '@angular/router';
import {
  AddAdmin, AddMember,
  AddNewSessionKeys, CreateConversation, DeleteConversation, GetContactList,
  GetConversations,
  GetFiles,
  GetMembersList,
  GetMessages,
  GetUpdatedPublicKeys, GetUserInfo, LeaveConversation, MarkAllReaded,
  MessagesReaded, RenameConversation,
  SessionKeyValue,
  Typing, UpdateContact
} from './request';
import {
  AddedMessageToConversation,
  AddedSessionKey,
  AddMemberToConversation,
  ConversationDeleted,
  ConversationLeaved,
  Conversations,
  Files,
  MembersList,
  Messages,
  NewPublicKey,
  NewSessionKey, RenameConversationNotification,
  ResponseObject,
  StatusMessage,
  TypingNotification,
  UpdatedPublicKeys,
  UpdatedMessageNotification, ContactList, CreatedConversation, UserInfo, ConversationAvatarChangedNotification
} from './response';
import {
  Contact,
  ContactDisplay,
  Conversation,
  ConversationDisplay,
  FileDownload,
  FileDownloadDisplay, FileParams,
  Message,
  MessageDisplay,
  SessionKey,
  User
} from './model';
import {forkJoin, filter, mergeMap, Observable, of, Subject, ValueFromArray, ReplaySubject} from "rxjs";
import {
  faChevronLeft,
  faPencilAlt,
  faFileDownload,
  faImages,
  faInfoCircle,
  faKey,
  faMale,
  faMicrophoneAlt,
  faPaperPlane,
  faPlayCircle,
  faPowerOff,
  faStop,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {UploadImageComponent} from "./upload-image.component";
import {Title} from "@angular/platform-browser";
import * as mime from "mime-types";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {InformationComponent} from "./information.component";
import {VerificationComponent} from "./verification.component";
import {FingerprintComponent} from "./fingerprint.component";
import {MatSnackBar, MatSnackBarRef, MatSnackBarConfig} from '@angular/material/snack-bar';
import {WarningComponent} from "./warning.component";
import {MatSelectionList} from "@angular/material/list";
import {MyfingerprintComponent} from "./myfingerprint.component";
import {ViewFileComponent} from "./view-file.component";


import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import {AddParticipantComponent} from "./add-participant.component";
import {map, switchMap} from "rxjs/operators";
import {additinalValidator} from "./login.component";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})


export class SearchComponent implements OnInit {

  conversations: ConversationDisplay[] = [];
  messages: MessageDisplay[] = [];
  private selectedUuid: string | null = null;
  searchStr =new FormControl('');

  faFileDownload = faFileDownload;
  faPlayCircle = faPlayCircle;
  windowInnerHeight =  window.innerHeight;
  private selectedConversationsList: MatSelectionList | null = null;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowInnerHeight = window.innerHeight;
    // this.windowInnerWidth = window.innerWidth;
    // this.height516 = window.innerHeight - 34 - 140;
    // this.height550 = window.innerHeight - 140;
    //
    // if (window.innerWidth <= 768 && this.privWidth > 768) {
    //   this.leftWidth = 100;
    //   this.rightWidth = 100;
    //   this.hideMessages = true;
    //   this.hideConversations = false;
    //   this.privWidth = window.innerWidth;
    // } else {
    //   if (window.innerWidth > 768 && this.privWidth <= 768) {
    //     this.leftWidth = 40;
    //     this.rightWidth = 60;
    //     this.hideMessages = false;
    //     this.hideConversations = false;
    //     this.privWidth = window.innerWidth;
    //
    //   }
    // }
  }

  constructor(private router: Router, private r: ActivatedRoute, public con: MessangerConnectionService,
              private titleService: Title, public dialog: MatDialog, private _snackBar: MatSnackBar) {

    if(r != null) {
      r.paramMap.pipe(
        switchMap((params: ParamMap) => of(params.get('uuid')))
      ).subscribe((uuidParam) => {
        this.selectedUuid = uuidParam as string;
        this.init()
      });
    } else {
      this.selectedUuid = null;
      this.init()

    }

    }

  allSubject = new ReplaySubject(100000000)
  init(): void {

    this.loadConversations((conversations: ConversationDisplay[], lastMessages: Message[], sessionKeys: SessionKey[]) => {
      if(this.selectedUuid == null) {
        conversations.forEach(c => c.selected = true)
      } else {
        conversations.forEach(c => {
          if(this.selectedUuid == c.uuid) {
            c.selected = true
          }
        })
      }
      this.conversations = conversations;
      const conversationDisplayObservable: Observable<ConversationDisplay> = new Observable(observer => {
        conversations.forEach( c=> {
          observer.next(c);
        })
        observer.complete();
      })
      conversationDisplayObservable.pipe(
        mergeMap(c => {
          let requestGetMessages = new GetMessages(c.uuid, null);
          requestGetMessages.size = 500;
          return this.con.sendRequestObservable(requestGetMessages)
        })
      ).pipe(
        mergeMap(r => {
          let messages = (r as Messages).messages;
          const observableMessage: Observable<Message> = new Observable(observer => {
            messages.forEach(m=> observer.next(m))
            observer.complete();
          })
          return observableMessage;
        })
      ).pipe(
        mergeMap(r=> {
          let md = new MessageDisplay();
          md.initBy(r);
          this.con.encryption.getNick(md.userId, md.conversationUuid, (nick: string) => md.userNick = nick);
          let mdObservable: Observable<MessageDisplay> = new Observable(observer => {
            this.con.encryption.decryptMessage(r, (body: string) => {
              md.body = body;
              observer.next(md)
              observer.complete();

            });
          })
          return mdObservable;
        })
      ).pipe(
        mergeMap(r => {
          let mdObservable: Observable<MessageDisplay> = new Observable(observer => {
            if(r.hasFiles) {
              let getFiles = new GetFiles(r.conversationUuid, r.uuid);
              this.con.sendRequest(getFiles, (responseObject) => {
                let response = responseObject as Files
                r.filesOrig = response.files
                observer.next(r)
                observer.complete()

              })

            } else {
              observer.next(r)
              observer.complete()
            }

          })
          return mdObservable;
          })
      ).pipe(
        mergeMap(r=> {
          let resArray:[any]=[of(r)];
          r.filesOrig.map(f=> {
            let o = new Observable(observer => {
              let fileNameEncrypted: string = f.fileNameEncrypted;
              let fd = new FileDownloadDisplay(fileNameEncrypted, "", "", "", true, new FileParams(f.width, f.height, f.duration, f.size, f.previewFileNameEncrypted, f.mimeType), f.previewFileNameEncrypted, f.downloadUrl);
              this.con.encryption.decryptFileWithoutBody(r, fd, (file: any) => {
                observer.next(fd)
                observer.complete()
              })
            })

            return o;
          }).forEach(o=>resArray.push(o))
          return forkJoin(resArray)
        })
      ).pipe(
        map(r=> {
          let ret = r[0];
          if(r.length >1) {
            const myClonedArray  = Object.assign([], r);
            myClonedArray.shift()
            ret.files = myClonedArray
          }
          console.log("recalc")
          return ret;
        })
      ).subscribe(r=> this.allSubject.next(r));


    })
    }


  private loadConversations(f: (conversations: ConversationDisplay[], lastMessages: Message[], sessionKeys: SessionKey[]) => void) {
    let requestGetConversations = new GetConversations();
    this.con.sendRequest(requestGetConversations,
      (responseObject) => {
        let responseConversations = responseObject as Conversations
        let conversations = (responseConversations as Conversations).conversations.sort((a, b) => {
          if (a.lastMessage.getTime() < b.lastMessage.getTime()) {
            return 1;
          }
          if (a.lastMessage.getTime() > b.lastMessage.getTime()) {
            return -1;
          }
          if (a.lastMessage.getTime() == b.lastMessage.getTime()) {
            return 0;
          }
          return  0;

        }).map(c => {
          let cd: ConversationDisplay = this.convertToConversationDisplay(c);
          return cd;
        });
        f(conversations, responseConversations.lastMessages, responseConversations.sessionKeys);
      }
    );


  }

  private convertToConversationDisplay(c: Conversation): ConversationDisplay {
    let cd: ConversationDisplay = new ConversationDisplay();
    cd.initBy(c, this.con.encryption);
    let requestGetMembersList = new GetMembersList(c.uuid);
    this.con.sendRequest(requestGetMembersList, (responseObject) => {
      let membersList = responseObject as MembersList
      cd.members = membersList.admins.concat(membersList.members);
      cd.members.forEach((userId) => {
        this.con.encryption.getNick(userId, c.uuid,(nick: string) => {
          if(this.con.bigBrother && this.con.adminId == userId) {
            // not add
          } else {
            if(nick != null) {
              cd.membersNick.push(nick);
            } else {
              if(userId == this.con.userId) {
                cd.membersNick.push("me");
              } else {
                cd.membersNick.push(userId);
              }
            }
          }
        });
      });
      if (c.isDialog) {
        let userId = membersList.admins.filter(u => u != this.con.userId).filter(c => {
          if(this.con.bigBrother && this.con.adminId == c) {
            return false;
          } else {
            return true;
          }
        })[0];

        cd.isAdmin = true;
        this.con.downloadAvatarForConversation(cd, userId, avatar => {
          cd.image = avatar;
        })
        this.con.encryption.getNick(userId, c.uuid,(nick: string) => cd.title = nick);
      } else {

        this.con.downloadAvatarForConversation(cd,"", avatar => {
          cd.image = avatar;
        })

        if (membersList.admins.filter(u => u == this.con.userId).length > 0) {
          cd.isAdmin = true;
        }
      }
    });
    cd.lastMessageDisplay = new MessageDisplay();
//    cd.lastMessageDisplay.body = "";
    return cd;
  }
  goBack() {
    this.router.navigate(['/messenger']);

  }

  getFileType(fileName: string) {
    return this.con.encryption.getFileType(fileName);

  }
  playAudio(body:any) {
    let audio = new Audio(URL.createObjectURL(body));
    audio.play();
  }
  downloadFile(f: FileDownloadDisplay, m: MessageDisplay) {

    let file$ = this.con.downloadFile(f.downloadUrl);
    file$.subscribe((results: ArrayBuffer) => {
      f.bodyEncrypted = this.arrayBufferToBase64(results);
      f.decryptFull(this.con,m, () => {
        saveData(f.body, f.fileName);
      })

    });
    alert("The file is downloaded and decrypted. The file download process will start in a few seconds or minutes. Depending on the size of the file. Please wait.")

  }

  confirmSearchKeys($event: KeyboardEvent) {
    setTimeout(()=> {
      let messages: MessageDisplay[] = [];
      this.images = new Map();
      let selectedConversationsUuids:string[] = this.conversations.filter(c=>c.selected).map(c=>c.uuid)
      let allMessages: Observable<MessageDisplay> = new Observable<MessageDisplay>(observer=> {
        this.allSubject.subscribe(r=> observer.next(r as MessageDisplay))
      });
      allMessages.pipe(
        filter(m => {
          if(selectedConversationsUuids.find(uuid => uuid == m.conversationUuid) == undefined) {
            return false;
          } else {
            return true;
          }
        })
      ).pipe(
        filter((m) => {
          let resultRet = false

          let md = m as MessageDisplay
          if(this.searchStr.value == "") {
            resultRet = false;
          } else {

            let parts:[string] = this.searchStr.value.toLocaleLowerCase().split(" ")
            let res = parts.map(part => md.body.toLocaleLowerCase().includes(part)).filter(x=> x==false)
            if (res.length == 0) {
              resultRet = true;
            }
            m.files.forEach(f=> {
              let parts:[string] = this.searchStr.value.toLocaleLowerCase().split(" ")
              let res = parts.map(part => f.fileName.toLocaleLowerCase().includes(part)).filter(x=> x==false)
              if (res.length == 0) {
                resultRet = true;
              }
            })
          }
          return resultRet;
        })
      ).subscribe(r=> {
        messages.push(r)
        messages = messages.sort((n1,n2) => n2.created.getTime() - n1.created.getTime())
      })

      setTimeout(()=> {
        this.messages = messages
      }, 100)

    }, 300)

  }



  private arrayBufferToBase64(buffer: any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  urlify(text:string):string {
    if(text != null) {
      let myString = new String(text);
      text = myString.replace(/<[^>]*>?/gm, '');
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      text= text.replace(urlRegex, '<a target="_blank" href="$1">$1</a>')
      let parts:[string] = this.searchStr.value.toLocaleLowerCase().split(" ")
      parts.forEach(part => {
        text = text.replace( new RegExp(part,"ig"), "<b>"+part+"</b>");
      })

      return text;
    } else return text;
  }


  logout() {
    window.location.href = '/';
  }
  strip(text:string):string {
    if(text != null) {
      let myString = new String(text);
      text = myString.replace(/<[^>]*>?/gm, '');
      return text;
    } else return text;
  }
  viewFile(message: MessageDisplay, f:FileDownloadDisplay ) {
    let files =  message.files;
    let file = files.find(file => {
      if(file.previewFileNameEncrypted == f.fileNameEncrypted) {
        return true;
      } else {
        return false;
      }
    }) as FileDownloadDisplay
    this.downloadImage(file, message)
    const dialogRef = this.dialog.open(ViewFileComponent, {
      width: '950px',
      data: {
        message: message,
        file: file
      }
    });
  }

  images: Map<string,boolean> = new Map();
  selectAll= "x";

  downloadImage(fCopy: FileDownloadDisplay, mCopy: MessageDisplay) {

    let that = this;

    let key = mCopy.uuid + mCopy.conversationUuid + fCopy.fileNameEncrypted
    if(!this.images.has(key)) {
      console.log("downloadImage")
      this.images.set(key, true);
      setTimeout(() => {
        let m = this.messages.find(x => x.uuid == mCopy.uuid) as MessageDisplay
        let f = m.files.find(x => x.fileNameEncrypted == fCopy.fileNameEncrypted) as FileDownloadDisplay
        let file$ = this.con.downloadFile(f.downloadUrl);
        file$.subscribe((results: ArrayBuffer) => {
          f.bodyEncrypted = this.arrayBufferToBase64(results);
          f.decryptFull(this.con,m, () => {

          })

        });
      }, 1000)


    }

    return true;
  }

  ngOnInit(): void {
  }

  click(c: ConversationDisplay) {
    c.selected = !c.selected
    // @ts-ignore
    this.confirmSearchKeys(null)
  }

  selectTrigger() {
    if(this.selectAll == "x") {
      this.conversations.forEach(c=> c.selected = false)
      this.selectAll = "+"
    } else {
      this.conversations.forEach(c=> c.selected = true)
      this.selectAll = "x"
    }
    // @ts-ignore
    this.confirmSearchKeys(null)
  }
}

var saveData = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  // a.style = "display: none";
  return function (data: any, fileName: any) {
    console.log(data);
    var url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
}());
