import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Request, Ping, GetUserInfo, RegisterApplication, ConfirmRegistration } from './request';
import { Response, StatusMessage, UserInfo } from './response';
import { MessangerConnectionService } from './messanger-connection.service';
import { Router } from '@angular/router';
import { Guid } from "guid-typescript";



@Component({
  selector: 'app-registration-app',
  templateUrl: './registration-app.component.html',
  styleUrls: ['./registration-app.component.css']
})
export class RegistrationAppComponent implements OnInit {
  codes = [
    {num:	93	, iso: "AF"},
    {num:	7	, iso: "RU"},
    {num:	1	, iso: "US"},
    {num:	355	, iso: "AL"},
    {num:	213	, iso: "DZ"},
    {num:	1684	, iso: "AS"},
    {num:	376	, iso: "AD"},
    {num:	244	, iso: "AO"},
    {num:	1264	, iso: "AI"},
    {num:	672	, iso: "AQ"},
    {num:	1268	, iso: "AG"},
    {num:	54	, iso: "AR"},
    {num:	374	, iso: "AM"},
    {num:	297	, iso: "AW"},
    {num:	61	, iso: "AU"},
    {num:	43	, iso: "AT"},
    {num:	994	, iso: "AZ"},
    {num:	1242	, iso: "BS"},
    {num:	973	, iso: "BH"},
    {num:	880	, iso: "BD"},
    {num:	1246	, iso: "BB"},
    {num:	375	, iso: "BY"},
    {num:	32	, iso: "BE"},
    {num:	501	, iso: "BZ"},
    {num:	229	, iso: "BJ"},
    {num:	1441	, iso: "BM"},
    {num:	975	, iso: "BT"},
    {num:	591	, iso: "BO"},
    {num:	387	, iso: "BA"},
    {num:	267	, iso: "BW"},
    {num:	55	, iso: "BR"},
    {num:	246	, iso: "IO"},
    {num:	1284	, iso: "VG"},
    {num:	673	, iso: "BN"},
    {num:	359	, iso: "BG"},
    {num:	226	, iso: "BF"},
    {num:	257	, iso: "BI"},
    {num:	855	, iso: "KH"},
    {num:	237	, iso: "CM"},
    {num:	1	, iso: "CA"},
    {num:	238	, iso: "CV"},
    {num:	1345	, iso: "KY"},
    {num:	236	, iso: "CF"},
    {num:	235	, iso: "TD"},
    {num:	56	, iso: "CL"},
    {num:	86	, iso: "CN"},
    {num:	61	, iso: "CX"},
    {num:	61	, iso: "CC"},
    {num:	57	, iso: "CO"},
    {num:	269	, iso: "KM"},
    {num:	682	, iso: "CK"},
    {num:	506	, iso: "CR"},
    {num:	385	, iso: "HR"},
    {num:	53	, iso: "CU"},
    {num:	599	, iso: "CW"},
    {num:	357	, iso: "CY"},
    {num:	420	, iso: "CZ"},
    {num:	243	, iso: "CD"},
    {num:	45	, iso: "DK"},
    {num:	253	, iso: "DJ"},
    {num:	1767	, iso: "DM"},
    {num:	1809	, iso: "DO"},
    {num:	670	, iso: "TL"},
    {num:	593	, iso: "EC"},
    {num:	20	, iso: "EG"},
    {num:	503	, iso: "SV"},
    {num:	240	, iso: "GQ"},
    {num:	291	, iso: "ER"},
    {num:	372	, iso: "EE"},
    {num:	251	, iso: "ET"},
    {num:	500	, iso: "FK"},
    {num:	298	, iso: "FO"},
    {num:	679	, iso: "FJ"},
    {num:	358	, iso: "FI"},
    {num:	33	, iso: "FR"},
    {num:	689	, iso: "PF"},
    {num:	241	, iso: "GA"},
    {num:	220	, iso: "GM"},
    {num:	995	, iso: "GE"},
    {num:	49	, iso: "DE"},
    {num:	233	, iso: "GH"},
    {num:	350	, iso: "GI"},
    {num:	30	, iso: "GR"},
    {num:	299	, iso: "GL"},
    {num:	1473	, iso: "GD"},
    {num:	1671	, iso: "GU"},
    {num:	502	, iso: "GT"},
    {num:	441481	, iso: "GG"},
    {num:	224	, iso: "GN"},
    {num:	245	, iso: "GW"},
    {num:	592	, iso: "GY"},
    {num:	509	, iso: "HT"},
    {num:	504	, iso: "HN"},
    {num:	852	, iso: "HK"},
    {num:	36	, iso: "HU"},
    {num:	354	, iso: "IS"},
    {num:	91	, iso: "IN"},
    {num:	62	, iso: "ID"},
    {num:	98	, iso: "IR"},
    {num:	964	, iso: "IQ"},
    {num:	353	, iso: "IE"},
    {num:	441624	, iso: "IM"},
    {num:	972	, iso: "IL"},
    {num:	39	, iso: "IT"},
    {num:	225	, iso: "CI"},
    {num:	1876	, iso: "JM"},
    {num:	81	, iso: "JP"},
    {num:	441534	, iso: "JE"},
    {num:	962	, iso: "JO"},
    {num:	7	, iso: "KZ"},
    {num:	254	, iso: "KE"},
    {num:	686	, iso: "KI"},
    {num:	383	, iso: "XK"},
    {num:	965	, iso: "KW"},
    {num:	996	, iso: "KG"},
    {num:	856	, iso: "LA"},
    {num:	371	, iso: "LV"},
    {num:	961	, iso: "LB"},
    {num:	266	, iso: "LS"},
    {num:	231	, iso: "LR"},
    {num:	218	, iso: "LY"},
    {num:	423	, iso: "LI"},
    {num:	370	, iso: "LT"},
    {num:	352	, iso: "LU"},
    {num:	853	, iso: "MO"},
    {num:	389	, iso: "MK"},
    {num:	261	, iso: "MG"},
    {num:	265	, iso: "MW"},
    {num:	60	, iso: "MY"},
    {num:	960	, iso: "MV"},
    {num:	223	, iso: "ML"},
    {num:	356	, iso: "MT"},
    {num:	692	, iso: "MH"},
    {num:	222	, iso: "MR"},
    {num:	230	, iso: "MU"},
    {num:	262	, iso: "YT"},
    {num:	52	, iso: "MX"},
    {num:	691	, iso: "FM"},
    {num:	373	, iso: "MD"},
    {num:	377	, iso: "MC"},
    {num:	976	, iso: "MN"},
    {num:	382	, iso: "ME"},
    {num:	1664	, iso: "MS"},
    {num:	212	, iso: "MA"},
    {num:	258	, iso: "MZ"},
    {num:	95	, iso: "MM"},
    {num:	264	, iso: "NA"},
    {num:	674	, iso: "NR"},
    {num:	977	, iso: "NP"},
    {num:	31	, iso: "NL"},
    {num:	599	, iso: "AN"},
    {num:	687	, iso: "NC"},
    {num:	64	, iso: "NZ"},
    {num:	505	, iso: "NI"},
    {num:	227	, iso: "NE"},
    {num:	234	, iso: "NG"},
    {num:	683	, iso: "NU"},
    {num:	850	, iso: "KP"},
    {num:	1670	, iso: "MP"},
    {num:	47	, iso: "NO"},
    {num:	968	, iso: "OM"},
    {num:	92	, iso: "PK"},
    {num:	680	, iso: "PW"},
    {num:	970	, iso: "PS"},
    {num:	507	, iso: "PA"},
    {num:	675	, iso: "PG"},
    {num:	595	, iso: "PY"},
    {num:	51	, iso: "PE"},
    {num:	63	, iso: "PH"},
    {num:	64	, iso: "PN"},
    {num:	48	, iso: "PL"},
    {num:	351	, iso: "PT"},
    {num:	1787	, iso: "PR"},
    {num:	974	, iso: "QA"},
    {num:	242	, iso: "CG"},
    {num:	262	, iso: "RE"},
    {num:	40	, iso: "RO"},
    {num:	250	, iso: "RW"},
    {num:	590	, iso: "BL"},
    {num:	290	, iso: "SH"},
    {num:	1869	, iso: "KN"},
    {num:	1758	, iso: "LC"},
    {num:	590	, iso: "MF"},
    {num:	508	, iso: "PM"},
    {num:	1784	, iso: "VC"},
    {num:	685	, iso: "WS"},
    {num:	378	, iso: "SM"},
    {num:	239	, iso: "ST"},
    {num:	966	, iso: "SA"},
    {num:	221	, iso: "SN"},
    {num:	381	, iso: "RS"},
    {num:	248	, iso: "SC"},
    {num:	232	, iso: "SL"},
    {num:	65	, iso: "SG"},
    {num:	1721	, iso: "SX"},
    {num:	421	, iso: "SK"},
    {num:	386	, iso: "SI"},
    {num:	677	, iso: "SB"},
    {num:	252	, iso: "SO"},
    {num:	27	, iso: "ZA"},
    {num:	82	, iso: "KR"},
    {num:	211	, iso: "SS"},
    {num:	34	, iso: "ES"},
    {num:	94	, iso: "LK"},
    {num:	249	, iso: "SD"},
    {num:	597	, iso: "SR"},
    {num:	47	, iso: "SJ"},
    {num:	268	, iso: "SZ"},
    {num:	46	, iso: "SE"},
    {num:	41	, iso: "CH"},
    {num:	963	, iso: "SY"},
    {num:	886	, iso: "TW"},
    {num:	992	, iso: "TJ"},
    {num:	255	, iso: "TZ"},
    {num:	66	, iso: "TH"},
    {num:	228	, iso: "TG"},
    {num:	690	, iso: "TK"},
    {num:	676	, iso: "TO"},
    {num:	1868	, iso: "TT"},
    {num:	216	, iso: "TN"},
    {num:	90	, iso: "TR"},
    {num:	993	, iso: "TM"},
    {num:	1649	, iso: "TC"},
    {num:	688	, iso: "TV"},
    {num:	1340	, iso: "VI"},
    {num:	256	, iso: "UG"},
    {num:	380	, iso: "UA"},
    {num:	971	, iso: "AE"},
    {num:	44	, iso: "GB"},
    {num:	598	, iso: "UY"},
    {num:	998	, iso: "UZ"},
    {num:	678	, iso: "VU"},
    {num:	379	, iso: "VA"},
    {num:	58	, iso: "VE"},
    {num:	84	, iso: "VN"},
    {num:	681	, iso: "WF"},
    {num:	212	, iso: "EH"},
    {num:	967	, iso: "YE"},
    {num:	260	, iso: "ZM"},
    {num:	263	, iso: "ZW"},

  ]


  smsCodeErrorHide:Boolean = true;
  phoneNumber: FormGroup;
  smsConfirmation: FormGroup;
  smsCode = new FormControl('', [Validators.required, Validators.minLength(3), additinalValidator(this)]);
  code = new FormControl('+7');
  userIdOpen = new FormControl('',
    [Validators.required, Validators.pattern("^[0-9]+$")]);

  hideSmsCode = true;

  focusSmsCode = false;

  private appUuid = Guid.create().toString();
  public errStr: string ="";

  constructor(private router: Router, public con: MessangerConnectionService, fb: FormBuilder) {

    try {
      // @ts-ignore
      let language = window.navigator.userLanguage || window.navigator.language;
      let iso = language.split("-")[1];
      let res = this.codes.find(x => x.iso == iso)
      if(res != undefined) {
        this.code.setValue("+" + res.num)
      }
    } catch (e) {

    }



    this.phoneNumber = fb.group({
      code: this.code,
      userId: this.userIdOpen,
    });

    this.smsConfirmation = fb.group({
      smsCode: this.smsCode
    })
  }

  registerApp() {
    console.log("registerApp");
    let requestRegisterApplication = new RegisterApplication(this.appUuid, "web",this.con.sha256Base64(this.code.value + this.userIdOpen.value), this.code.value + this.userIdOpen.value,

      )

    this.con.sendRequest(requestRegisterApplication,
      (responseRegistration) => {
          if((responseRegistration as StatusMessage).status == 'ok') {
            this.hideSmsCode = false;
            setTimeout(()=>{ this.focusSmsCode = true }, 500);
          } else {
            alert((responseRegistration as StatusMessage).description);
          }

      }
    );
  }

  confirmApp() {
    let confirmRegistration = new ConfirmRegistration(this.appUuid, this.smsCode.value, this.con.sha256Base64(this.code.value + this.userIdOpen.value))
    this.con.sendRequest(confirmRegistration,
      (responseConfirm) => {
          if((responseConfirm as StatusMessage).status == 'ok') {
            this.con.storage.saveAppUuidAndUserId(this.appUuid, this.con.sha256Base64(this.code.value + this.userIdOpen.value), this.code.value + this.userIdOpen.value)
            this.con.appUuid = this.appUuid;
            this.con.userId = this.con.sha256Base64(this.code.value + this.userIdOpen.value);
            this.con.userIdOpen = this.code.value + this.userIdOpen.value;


            this.con.authorizeApp(() => {


              let requestGetUserInfo = new GetUserInfo( this.con.userId)
              this.con.sendRequest(requestGetUserInfo,
                (responseObject) => {
                let responseUserInfo  = responseObject as UserInfo
                  if(responseUserInfo.user.privateKeyEncryptedBase64 != null) {
                    this.con.user = responseUserInfo.user;
                    window.location.href="/login"
                  } else {
                    this.router.navigate(['/registration']);
                  }
                }
              );


            }, ()=>{
              console.log("error authorization");
              this.router.navigate(['/']);
            });
          } else {
            this.smsCodeErrorHide = false;
            this.smsCode.updateValueAndValidity();
            this.errStr = (responseConfirm as StatusMessage).description
            setTimeout(()=> {
              this.smsCodeErrorHide = true;
            }, 2000)
          }
      }
    );
  }


  ngOnInit() {

    if(this.con.appUuid != null) {
      this.router.navigate(['/']);
    } else {

    }


  }

  registerAppKeys(event: any) {
    if (event.keyCode == 13) {
      if(this.userIdOpen.valid) {
        this.registerApp();
      }
    }
  }

  confirmAppKeys(event: any) {
    if (event.keyCode == 13) {
      if(this.smsCode.valid) {
        this.confirmApp();
      }
    }

  }


  back() {
    this.hideSmsCode = true;
    this.con.clear();
  }

  formatPhone(str: String) {
    return this.con.formatPhone(str);
  }
}

export function additinalValidator(trigger: RegistrationAppComponent): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!trigger.smsCodeErrorHide) {
    return {additinal: {valid: false}} ;
    } else {
      return null;
    }
  };
}
