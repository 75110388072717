import {Encryption} from "./encryption";
import {MessangerConnectionService} from "./messanger-connection.service";
import {Files} from "./response";

export const EventAdded = "Added";
export const EventRemoved = "Removed";
export const EventLeaved = "Leaved";
export const EventSessionKeyRegenerated = "SessionKeyRegenerated";


export class User {
  userId: string = "";
  publicKeyBase64: string = "";
  privateKeyEncryptedBase64: string = "";
  metaDataKeyEncryptedBase64: string = "";
  created: Date = new Date();
  lastLogin: Date = new Date();
  nick: string = "";
  keyPairUuid:string = "";


  constructor(userId: string, publicKeyBase64: string, privateKeyEncryptedBase64: string, created: Date, lastLogin: Date, nick: string, metaDataKeyEncryptedBase64: string) {
    this.userId = userId;
    this.publicKeyBase64 = publicKeyBase64;
    this.privateKeyEncryptedBase64 = privateKeyEncryptedBase64;
    this.metaDataKeyEncryptedBase64 = metaDataKeyEncryptedBase64;
    this.created = created;
    this.lastLogin = lastLogin;
    this.nick = nick;
  }
}

export class Conversation {
  uuid: string = "";
  isDialog: boolean = false;
  title: string = "";
  sessionKeyUuid: string = "";
  created: Date = new Date();
  lastMessage: Date = new Date();
  unreadedCounter:number = 0;


}


export class ConversationDisplay {
  uuid: string = "";
  isDialog: boolean = false;
  title: string = "";
  titleSrc: string = "";
  image: string = "/download/avatars/user-profile.jpeg";
  sessionKeyUuid: string = "";
  created: Date = new Date();
  lastMessage: Date = new Date();
  lastMessageDisplay: MessageDisplay = new MessageDisplay();
  isAdmin: boolean = false;
  unreadedCounter:number = 0;
  members: string[] = [];
  membersNick: string[] = [];
  selected: boolean = false;

  firstUnreadMessageUuid: string| null = null;

  initBy(c: Conversation, encryption: Encryption) {
    this.uuid = c.uuid;
    this.isDialog = c.isDialog;
    this.titleSrc = c.title;
    if(this.isDialog == false) {
      this.title = "";
      try {
        encryption.decryptBase64BySessionKey(c.title, (str) => {
          this.title = str;
        })
      } catch (e) {

      }
    }
    this.sessionKeyUuid = c.sessionKeyUuid;
    this.created = c.created;
    this.lastMessage = c.lastMessage;
    this.unreadedCounter = c.unreadedCounter;
  }
}


export class Message {
  uuid: string = "";
  conversationUuid: string = "";
  sessionKeyUuid: string = "";
  userId: string = "";
  ivBase64: string = "";
  bodyEncryptedBase64: string = "";
  created: Date = new Date();
  hasFiles: boolean = false;
  readed: boolean = false;
  userNick: string = "";
  isEvent: boolean = false;
  eventType: string = "";
  eventUserId: string = "";
  readedMembers: number = 0;
  deliveredMembers: number = 0;

  deleted: boolean = false;
  members: number = 0;
  constructor(uuid: string, conversationUuid: string, sessionKeyUuid: string, userId: string, ivBase64: string, bodyEncryptedBase64: string, created: Date, hasFiles: boolean, readed: boolean, userNick: string, isEvent: boolean, eventType: string, eventUserId: string) {
    this.uuid = uuid;
    this.conversationUuid = conversationUuid;
    this.sessionKeyUuid = sessionKeyUuid;
    this.userId = userId;
    this.ivBase64 = ivBase64;
    this.bodyEncryptedBase64 = bodyEncryptedBase64;
    this.created = created;
    this.hasFiles = hasFiles;
    this.readed = readed;
    this.userNick = userNick;
    this.isEvent = isEvent;
    this.eventType = eventType;
    this.eventUserId = eventUserId;
  }
}

export class SessionKey {
  uuid: string = "";
  conversationUuid: string = "";
  userId: string = "";
  keyEncryptedBase64: string = "";
  signatureBase64: string = "";
  signerUserId: string = "";
  created: Date = new Date();

  signerKeyPairUuid: string = "";

}

var oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 10);

export class MessageDisplay {
  uuid: string = "";
  conversationUuid: string = "";
  sessionKeyUuid: string = "";
  userId: string = "";
  body: string = "";
  created: Date = oneYearFromNow;
  hasFiles: boolean = false;
  userNick: string = "";
  readed: boolean = false;
  ivBase64: string = "";
  files: FileDownloadDisplay[] = [];

  filesOrig: FileDownload[] = [];
  readedMembers: number = 0;
  deliveredMembers: number = 0;

  members: number = 0;

  needUpdateUnreadCnt: boolean = false;

  initBy(message: Message) {

    this.uuid = message.uuid;
    this.conversationUuid = message.conversationUuid;
    this.sessionKeyUuid = message.sessionKeyUuid;
    this.userId = message.userId;
    this.created = message.created;
    this.readed = message.readed;
    this.hasFiles = message.hasFiles;
    this.ivBase64 = message.ivBase64
    this.readedMembers = message.readedMembers;
    this.deliveredMembers = message.deliveredMembers;
    this.members = message.members;
  }
}

export class Contact {
  contactId: string = "";
  contactIdEncrypted:  string = "";
  nickEncrypted:  string = "";
  trustedFingerprintEncrypted:  string | null = null;

  constructor(contactId: string, contactIdEncrypted: string, nickEncrypted: string, trustedFingerprintEncrypted: string | null) {
    this.contactId = contactId;
    this.contactIdEncrypted = contactIdEncrypted;
    this.nickEncrypted = nickEncrypted;
    this.trustedFingerprintEncrypted = trustedFingerprintEncrypted;
  }
}


export class ContactDisplay {
  contactId: string = "";
  contactIdOpen:  string = "";
  nickOpen:  string = "";
  trustedFingerprintOpen:  string | null = null;
  contactIdEncrypted:  string = "";
  nickEncrypted:  string = "";
  trustedFingerprintEncrypted:  string | null = null;
  avatarSrc: string = "";
  checked: boolean = false;

  initBy(contact: Contact, encryption: Encryption) {
    this.contactIdEncrypted = contact.contactIdEncrypted;
    this.nickEncrypted = contact.nickEncrypted;
    this.trustedFingerprintEncrypted = contact.trustedFingerprintEncrypted;
    this.contactId = contact.contactId;
    this.contactIdOpen = encryption.decryptByMetaDataKey(contact.contactIdEncrypted) as string;
    if(contact.nickEncrypted != null) {
      this.nickOpen = encryption.decryptByMetaDataKey(contact.nickEncrypted) as string;
    }
    if(contact.trustedFingerprintEncrypted != null) {
      this.trustedFingerprintOpen = encryption.decryptByMetaDataKey(contact.trustedFingerprintEncrypted) as string;
    }
  }


  constructor(contactId: string, contactIdOpen: string, nickOpen: string, trustedFingerprintOpen: string, contactIdEncrypted: string, nickEncrypted: string, trustedFingerprintEncrypted: string | null, avatarSrc: string) {
    this.contactId = contactId;
    this.contactIdOpen = contactIdOpen;
    this.nickOpen = nickOpen;
    this.trustedFingerprintOpen = trustedFingerprintOpen;
    this.contactIdEncrypted = contactIdEncrypted;
    this.nickEncrypted = nickEncrypted;
    this.trustedFingerprintEncrypted = trustedFingerprintEncrypted;
    this.avatarSrc = avatarSrc;
  }
}

export class Application {
  uuid: string = "";
  smsCode: string = "";
  applicationType: string = "";
  confirmed: boolean = true;
  created: Date = new Date();
  userId: string = "";
  deviceToken: string = "";

}

export class ApplicationFull {
  uuid: string = "";
  smsCode: string = "";
  applicationType: string = "";
  confirmed: boolean = true;
  created: Date = new Date();
  userId: string = "";
  deviceToken: string = "";
  lastLogin: Date = new Date();
  online: boolean = false;
}

export class FileDownload {
  fileNameEncrypted: string = "";
  downloadUrl: string = "";
  width: number = 0;
  height: number = 0;
  duration: number = 0;
  size: number = 0;
  previewFileNameEncrypted: string = "";
  mimeType: string = "";
}


export class DataEncryptedByMetaDataKey {

  ivBase64: string = "";
  encryptedBodyBase64: string = "";


  constructor(ivBase64: string, encryptedBodyBase64: string) {
    this.ivBase64 = ivBase64;
    this.encryptedBodyBase64 = encryptedBodyBase64;
  }
}


export class DataEncryptedBySessionKey {

  sessionKeyUuid: string = "";
  conversationUuid: string = "";
  ivBase64: string = "";
  encryptedBodyBase64: string = "";



  static initBy(json: string)  {
    let ret = new DataEncryptedBySessionKey("", "", "", "");
    let object = JSON.parse(json);
    Object.assign(ret, object);
    return ret;
  }


  constructor(sessionKeyUuid: string, conversationUuid: string, ivBase64: string, encryptedBodyBase64: string) {
    this.sessionKeyUuid = sessionKeyUuid;
    this.conversationUuid = conversationUuid;
    this.ivBase64 = ivBase64;
    this.encryptedBodyBase64 = encryptedBodyBase64;
  }
}

export class FileDownloadDisplay {
  fileNameEncrypted: string = "";
  fileName: string = "";
  bodyEncrypted: string = "";
  body: Blob | string = "";
  hidden = true;
  params: FileParams = new FileParams(0, 0, 0, 0, null, "");
  downloadUrl: string = "";
  previewFileNameEncrypted: string = "";
  decrypt(connection: MessangerConnectionService , message: MessageDisplay,  f: () => void) {
    let that = this
    connection.encryption.decryptFileWithoutBody(message, this, (file: any) => {
      f();
    })
  }
  decryptFull(connection: MessangerConnectionService , message: MessageDisplay, f: () => void) {
    let that = this
    connection.encryption.decryptFileFull(message, this, (file: any) => {
      f();
    })
  }

  constructor(fileNameEncrypted: string, fileName: string, bodyEncrypted: string, body: Blob | string, hidden: boolean, params: FileParams, previewFileNameEncrypted: string, url: string) {
    this.downloadUrl = url;
    this.fileNameEncrypted = fileNameEncrypted;
    this.fileName = fileName;
    this.bodyEncrypted = bodyEncrypted;
    this.body = body;
    this.hidden = hidden;
    this.params = params;
    this.previewFileNameEncrypted = previewFileNameEncrypted;
  }
}

export class  FileParams {

  width: number = 0;
  height: number = 0;
  duration: number = 0;
  size: number = 0;
  previewFileNameEncrypted: string | null = null;
  mimeType: string = "";

  constructor(width: number, height: number, duration: number, size: number, previewFileNameEncrypted: string | null, mimeType: string) {
    this.width = width;
    this.height = height;
    this.duration = duration;
    this.size = size;
    this.previewFileNameEncrypted = previewFileNameEncrypted;
    this.mimeType = mimeType;
  }
}

export class FileEncrypted {
  fileNameBase64: string;
  fileContent: string[] = [];
  fileParams: FileParams;

  constructor(fileNameBase64: string, fileContent: string[], fileParams: FileParams) {
    this.fileNameBase64 = fileNameBase64;
    this.fileContent = fileContent;
    this.fileParams = fileParams;
  }
}

export class KeyPair  {
  uuid: string = "";
  userId: string = "";
  publicKeyBase64: string = "";
  privateKeyEncryptedBase64: string = "";
}

