<h1 mat-dialog-title  align="center">{{nick}} {{phone}}</h1>
<div mat-dialog-content align="center">

  <img [src]="avatarSrc"  style="border-radius: 50%;" width="40" height="40">

  <br>
  <br>

  <img src="/assets/{{emoji1}}.png" width="40" height="40">
  <img src="/assets/{{emoji2}}.png" width="40" height="40">
  <img src="/assets/{{emoji3}}.png" width="40" height="40">
  <img src="/assets/{{emoji4}}.png" width="40" height="40"><br>

  {{fingerprint1}}<br>
  {{fingerprint2}}<br>
  {{fingerprint3}}<br>
  {{fingerprint4}}<br>


</div>
<div mat-dialog-actions  align="center">
  <button mat-button [mat-dialog-close]="true">Close</button>
</div>
