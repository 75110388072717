



<div style="text-align: center;">
  <br>
  <img [src]="avatar" alt="profile photo" width="200" height="200" style="object-fit: cover;">
  <input #clickUpload type="file" accept=".jpg,.jpeg" style="display: none"
         id="file"  mat-flat-button mat-button color="primary"
         (change)="handleFileInput($event)">
  <span style="font-size: 10px;"><br>Accept only JPEG format for avatar.<br></span>
    <button  (click)="upload()" [disabled]="files == null"  mat-flat-button mat-button color="primary"  style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};" >Upload</button>
</div>




    <form [formGroup]="nickForm">
      <mat-form-field appearance="standard" style="padding: 20px 0px 0px 20px">
        <mat-label>Nickname</mat-label>
        <input matInput #input [autofocus]="true" [formControl]="nick" (keydown)="nickChanged = true" minlength="3" maxlength="128" >
      </mat-form-field>
      <button (click)="updateNickSave()" [disabled]="!nickChanged" mat-flat-button mat-button color="primary"  style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};" >Save</button>
    </form>


