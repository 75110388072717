<app-nav></app-nav>
<mat-grid-list cols="3" rowHeight="1:1" style="background: #d1d8e0;">

  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile>
    <mat-card style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.43);">




      <div [hidden]="showProgress || !hideSmsCode"  style="padding: 10px 5px 5px 5px;">
        <span style="padding: 0px 0px 0px 0px;font-family: Roboto;font-size: 20px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #4a4a4a;">
          Setup new password for {{formatPhone(connection.userId)}}
        </span>
        <span style="color: red;">
        <br>
        <br>
        Your keys will be regenerated with new password.<br>
        After that old encrypted data (messages, images and etc)<br> will be not decrypted more</span>

        <form [formGroup]="newPasswordForm"  style="padding: 20px 0px 0px 0px;">


          <p>
            <mat-form-field appearance="standard" style="width: 400px;">
              <mat-label>Create password</mat-label>
              <input [autofocus]="forgetFocus" matInput #input  [type]="hide ? 'password' : 'text'" [formControl]="passwordNew" (keydown)="updateKeysKey($event)">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-hint>At least 8 symbols</mat-hint>
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="standard" style="width: 400px;">
              <mat-label>Confirm password</mat-label>
              <input matInput #input     [type]="hide ? 'password' : 'text'"  [formControl]="passwordNewR" (keydown)="updateKeysKey($event)" >
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </p>


        </form>
        <div style="text-align: right;">
          <a href="/login" mat-button style="align-items: flex-end; margin-right: 10px">< Back </a>
          <button (click)="updateKeys()" [disabled]="!passwordNew.valid || !(passwordNew.value == passwordNewR.value)" mat-flat-button mat-button color="primary"  style="color: white;align-items: flex-end; " >Change password</button>
        </div>

      </div>



      <div [hidden]="!showProgress">
        <p>Keys generation in progress... Please wait...</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      </div>



      <div [hidden]="hideSmsCode"  style="padding: 10px 5px 5px 5px;">
        <span style="padding: 0px 0px 0px 0px;font-family: Roboto;font-size: 20px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #4a4a4a;">
        SMS send to {{formatPhone(connection.userId)}}
        </span>


        <form [formGroup]="smsConfirmation">
          <mat-form-field appearance="standard" style="padding: 20px 0px 0px 20px">
            <mat-label>SMS code</mat-label>
            <input matInput #input  (keydown)="confirmKeys($event)" [autofocus]="focusSmsCode" [formControl]="smsCode">
            <mat-error *ngIf="!smsCodeErrorHide">
              Incorrect sms code
            </mat-error>
          </mat-form-field>
        </form>
        <div style="text-align: right;">
          <a  href="/forget" mat-button style="width: 104px;align-items: flex-end; margin-right: 10px">< Back </a>
          <button (click)="confirm()" [disabled]="!smsCode.valid"   mat-flat-button mat-button color="primary"  style="color: white; width: 104px;align-items: flex-end; " >Next ></button>
        </div>

      </div>

    </mat-card>




  </mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>

  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>

  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>


</mat-grid-list>



