<h1 mat-dialog-title>Verify security {{data.nickOpen}}  {{data.contactIdOpen}}</h1>
<div mat-dialog-content align="center" style="font-size: 10px; line-height: 1.3;">

  <img src="/assets/{{emoji1}}.png" width="30" height="30">
  <img src="/assets/{{emoji2}}.png" width="30" height="30">
  <img src="/assets/{{emoji3}}.png" width="30" height="30">
  <img src="/assets/{{emoji4}}.png" width="30" height="30"><br>

  {{fingerprint1}}<br>
  {{fingerprint2}}<br>
  {{fingerprint3}}<br>
  {{fingerprint4}}<br>

  <p>Images and digits above were generated on {{data.nickOpen}}'s encryption key.<br>
  If they look the same {{data.nickOpen}}'s device, messages are end-to-end encrypted.</p>


</div>
<div mat-dialog-actions  align="center">
  <button mat-button [mat-dialog-close]="true">Verify</button>
  <button mat-button [mat-dialog-close]="false">Later</button>
</div>
