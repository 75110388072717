import {Component, Input, OnInit} from '@angular/core';
import {MessangerConnectionService} from "./messanger-connection.service";
import {HttpRequest} from "@angular/common/http";
import {ConversationDisplay, FileParams, Message} from "./model";
import {FormControl} from "@angular/forms";
import * as mime from "mime-types";
import { Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// @ts-ignore
import * as forge from 'node-forge'
import { Buffer } from 'buffer';


export interface  UploadImageData {
  uuid: string;
  sessionKeyUuid: string;
}

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  fileToUpload: File = {} as File;
  message = new FormControl('');


  files: FileList = {} as FileList;

  constructor(private connection: MessangerConnectionService,
              public dialogRef: MatDialogRef<UploadImageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UploadImageData
  ) {

  }

  handleFileInput(event: Event) {
    const target= event.target as HTMLInputElement;
    this.files = (target.files as FileList)

  }


  ngOnInit() {
  }



  compressImage(src: string, p1: FileParams, p2: FileParams) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        p1.width = img.width;
        p1.height = img.height;
        if(img.width > img.height) {
          elem.width = 250;
          elem.height = 250 * (img.height / img.width);
        } else {
          elem.height = 250;
          elem.width = 250 * (img.width / img.height);
        }
        p2.width = elem.width;
        p2.height = elem.height;
        const ctx = elem.getContext('2d');
        if(ctx != null) {
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob((r) => {
            res(r);
          });

        }
      }
      img.onerror = error => rej(error);
    })
  }

  upload() {
    let mimeTYPE = mime.lookup((this.files[0] as File).name) as string;
    let files: File[] = [];
    let params: FileParams[] = [];
    if(mimeTYPE == 'image/jpeg' || mimeTYPE == 'image/png') {
        let parts = (this.files[0] as File).name.split(".");
        let name = (this.files[0] as File).name.split(".")[0];
        let ext = (this.files[0] as File).name.split(".")[parts.length-1];
        let file = (this.files[0] as File);
        let mimeType = mime.lookup((this.files[0] as File).name) as string;
        let fileBytes = file.slice();
        let f = new File([fileBytes],name + '.' + ext.toLowerCase());
        files.push(f);
        let p = new FileParams(0,0,0,fileBytes.size,'preview_' +name + '.' + ext.toLowerCase(), mimeType);
        params.push(p);

      let preview_name = (this.files[0] as File).name.split(".")[0];
      let preview_ext = (this.files[0] as File).name.split(".")[1];
      let preview_mimeType = mime.lookup((this.files[0] as File).name) as string;
      let preview_p = new FileParams(0,0,0,new Blob([fileBytes]).size,null, preview_mimeType)
      fileBytes.arrayBuffer().then(bytes => {
        let buf = Buffer.from(bytes)
        let arr = Uint8Array.from(buf)
        let newbuf = forge.util.createBuffer(arr)
        let base64 = forge.util.encode64(buf.toString('binary'))
        this.compressImage("data:image/jpeg;base64," + base64, p, preview_p).then(compressed => {
          let preview_f = new File([compressed as Blob],'preview_' +preview_name + '.' + ext.toLowerCase());
          files.push(preview_f);
          params.push(preview_p);
          this.connection.uploadFiles(files, params ,this.message.value, this.data.uuid, this.data.sessionKeyUuid, () => {
            this.dialogRef.close()
          });
        })

      })


    } else {
      for(let i=0; i < this.files.length; i++) {
        let parts = (this.files[0] as File).name.split(".");
        let name = (this.files[0] as File).name.split(".")[0];
        let ext = (this.files[0] as File).name.split(".")[parts.length-1];
        let file = (this.files.item(i) as File);
        let mimeType = mime.lookup((this.files.item(i) as File).name) as string;
        let fileBytes = file.slice();
        let f = new File([fileBytes],name + '.' + ext.toLowerCase());
        files.push(f);
        params.push(new FileParams(0,0,0,fileBytes.size,null, mimeType));
      }
      this.connection.uploadFiles(files, params ,this.message.value, this.data.uuid, this.data.sessionKeyUuid, () => {
        this.dialogRef.close()
      });


    }

  }
}
