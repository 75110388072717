<div class="m-3 p-3" [hidden]="hidden" >

  <div class="card bg-light mb-3" style="max-width: 40em;">
    <div class="card-header">Create</div>
    <div class="card-body">

      <div>
        <div title="Dialog">
          <ng-template ngbTabContent>

            <div *ngIf="contacts.length==0" class="alert alert-danger" role="alert">
              You cant create dialog because your contact list empty. <a style="color: blue;"   routerLink="/settings" routerLinkActive="active">Add contacts?</a>
            </div>
            <form [formGroup]="formDialog" [hidden]="contacts.length==0">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nickname or full name</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let contact of contacts; let i = index;">
                  <td>
                    <input type="radio" formControlName="userId" [value]="contact.contactId">
                    <label>&nbsp;&nbsp;&nbsp;{{ contact.contactId }}</label>
                  </td>
                  <td>{{ contact.nickEncrypted }}</td>
                </tr>
                </tbody>
              </table>
              <button (click)="create(true)" [disabled]="formDialog.get('userId') == null" class="btn btn-primary">Create</button>
            </form>
          </ng-template>
        </div>
        <div title="Group chat">
          <ng-template ngbTabContent>


            <div [hidden]="contacts.length!=0" class="alert alert-danger" role="alert">
              You cant create group chat because your contact list empty.
            </div>

            <form [formGroup]="formConversation" [hidden]="contacts.length==0" (change)="selectCheckBox()">

              <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="title" aria-describedby="titleHelp"
                       placeholder="Title">
                <small id="titleHelp" class="form-text text-muted">Enter title please</small>
              </div>

              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nickname or full name</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let contact of contacts; let i = index;">
                  <td>
                    <input type="checkbox" [formControlName]="contact.contactId"> {{contact.contactId}}
                  </td>
                  <td>{{ contact.nickEncrypted }}</td>
                </tr>
                </tbody>
              </table>


              <button (click)="create(false)" [disabled]="selectedUsers.length == 0 || !formConversation.valid"  class="btn btn-primary">Create</button>
            </form>


          </ng-template>
        </div>
      </div>


    </div>
  </div>
</div>

