import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VerificationData} from "./verification.component";
import {MessangerConnectionService} from "./messanger-connection.service";

export interface FingerprintData {
  contactId: string;
  contactIdOpen:  string;
  nickOpen:  string;
  publicKeyBase64: string;
}

@Component({
  selector: 'app-fingerprint',
  templateUrl: './fingerprint.component.html',
  styleUrls: ['./fingerprint.component.css']
})
export class FingerprintComponent implements OnInit {
  fingerprint1: string;
  fingerprint2: string;
  fingerprint3: string;
  fingerprint4: string;


  emoji1: string;
  emoji2: string;
  emoji3: string;
  emoji4: string;

  constructor(
    public dialogRef: MatDialogRef<FingerprintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FingerprintData,
    public connection: MessangerConnectionService

  ) {

    let fingerprint = connection.encryption.fingerprintByPublicKey(data.publicKeyBase64);
    let fp = new FingerPrint();
    let ret = fp.fingerprintArrayStr(fingerprint)
    this.fingerprint1 = ret[0];
    this.fingerprint2 = ret[1];
    this.fingerprint3 = ret[2];
    this.fingerprint4 = ret[3];
    let retEmoji = fp.fingerprintArrayCodes(fingerprint);
    this.emoji1 = retEmoji[0];
    this.emoji2 = retEmoji[1];
    this.emoji3 = retEmoji[2];
    this.emoji4 = retEmoji[3];
  }

  ngOnInit(): void {
  }

}

export class FingerPrint {

  fingerprintArrayStr(fingerprint: string) {

    fingerprint =fingerprint.toUpperCase().replace(/(.{4})/g,"$1 ");
    let ret = [];
    ret.push(fingerprint.substring(0, 19));
    ret.push(fingerprint.substring(19, 40));
    ret.push(fingerprint.substring(40, 59));
    ret.push(fingerprint.substring(59, 79));
    return ret;
  }

  fingerprintArrayCodes(fingerprint: string) {
    fingerprint = fingerprint.toUpperCase();
    let emojiCodesSrc: string[] = [
      "1f600",
      "1f641",
      "1f48b",
      "2764",
      "1f4a3",
      "1f590",
      "1f44d",
      "1f442",
      "1f463",
      "1f431",
      "1f436",
      "1f430",
      "1f41c",
      "1f41e",
      "1f41d",
      "1f577",
      "1f339",
      "1f34c",
      "1f9c0",
      "1f32d",
      "1f52a",
      "2600",
      "2601",
      "1f3a7",
      "2328",
      "1f5b1",
      "270f",
      "1f511",
      "1f52b",
      "1f6bd",
      "1f6ac",
      "262f",
      "2721",
      "271d",
      "1f4b2",
      "26b0",
      "2699",
      "1f527",
      "1f528",
      "26cf",
      "2692",
      "1f4bc",
      "1f4ce",
      "1f4cf",
      "1f4d0",
      "2702",
      "1f4cc",
      "1f4c6",
      "1f58a",
      "1f58c",
      "1f4cb",
      "1f4b3",
      "1f56f",
      "1f4d8",
      "1f4da",
      "1f4b0",
      "1f50e",
      "1f4f7",
      "1f4f9",
      "1f3b8",
      "1f3bc",
      "1f45f",
      "1f460",
      "1f576",
      "1f454",
      "1f455",
      "1f456",
      "1f457",
      "1f459",
      "1f45c",
      "1f48e",
      "1f392",
      "1f3b2",
      "1f3ae",
      "1f3c9",
      "1f3d0",
      "26bd",
      "1f680",
      "1f6a6",
      "2708",
      "1f6a2",
      "1f691",
      "26ea",
      "1f54c",
      "1f5fc",
      "1f5fd",
      "1f37b",
      "1f37a",
      "1f377",
      "1f36c",
      "1f3fa",
      "1f382",
      "1f36b",
      "1f40e",
      "1f476",
      "1f450",
      "1f440",
      "1f441",
      "1f44c",
      "1f443",
      "1f4a9",
      "1f608",
      "1f607",
      "1f34d",
      "1f332",
      "1f37f",
      "231a",
      "23f0",
      "1f319",
      "1f4fa",
      "267b",
      "1f686",
      "1f321",
      "1f3b1",
      "1f695",
      "1f3cd",
      "1f6b2",
      "1f6e2",
      "2693",
      "1f485",
      "1f4aa",
      "1f46e",
      "1f385",
      "1f40d",
      "1f422",
      "1f40a",
      "1f363",
      "1f354",
      "1f35f",
      "1f355",
      "1f345",
      "1f356",
      "1f336",
      "1f352",
      "1f369",
      "26fa",
      "1f3b7",
      "1f3bb",
      "1f3ba",
      "1f526",
      "1f4a1",
      "26d3",
      "1f4c1",
      "1f489",
      "1f6aa",
      "1f48a",
      "1f47b",
      "1f60e",
      "1f637",
      "1f912",
      "1f916",
      "1f47d",
      "1f981",
      "1f984",
      "1f416",
      "1f404",
      "1f410",
      "1f42a",
      "1f418",
      "1f401",
      "1f414",
      "1f42c",
      "1f40c",
      "1f335",
      "1f334",
      "1f347",
      "1f349",
      "1f34b",
      "1f30b",
      "26fd",
      "1f6a7",
      "1f3d2",
      "1f399",
      "2622",
      "2623",
      "1f3f9",
      "2696",
      "1f6e1",
      "1f3a5",
      "1f427",
      "1f54a",
      "1f982",
      "1f41f",
      "1f419",
      "1f41a",
      "1f980",
      "1f578",
      "1f37c",
      "1f682",
      "1f681",
      "2b50",
      "1f3c6",
      "1f5de",
      "1f4f6",
      "1f3c1",
      "1f373",
      "1f37d",
      "2602",
      "1f30a",
      "1f4a7",
      "1f525",
      "1f3bf",
      "1f3a3",
      "1f3ca",
      "1f3cb",
      "1f344",
      "1f3e6",
      "1f3e5",
      "1f3ed",
      "1f3df",
      "1f692",
      "1f693",
      "1f3a1",
      "1f3aa",
      "1f3a2",
      "1f6e3",
      "1f6e4",
      "1f484",
      "1f39e",
      "1f4be",
      "1f4bf",
      "1f4e6",
      "1f58d",
      "1f52c",
      "1f52d",
      "1f4e1",
      "1f6bf",
      "1f5d1",
      "26d4",
      "1f4ee",
      "1f3b0",
      "1f381",
      "1f380",
      "26c4",
      "1f3be",
      "1f3b3",
      "2660",
      "2665",
      "2666",
      "2663",
      "1f5bc",
      "1f0cf",
      "1f5a5",
      "2709",
      "1f4f1",
      "1f6f0",
      "1f69c",
      "1f5e3",
      "1f44f",
      "270a",
      "270c",
      "1f467",
      "1f466",
      "1f4ac",
      "1f4a8",
      "1f4af"
    ];
    let emojiCodesArray: string[] = [];
    for (let i = 1; i <= 256/8; i++) {
      emojiCodesSrc.forEach(el => emojiCodesArray.push(el));
    }

    let ret = [];
    ret.push(emojiCodesArray[parseInt(fingerprint.substring(0, 2), 16)]);
    ret.push(emojiCodesArray[parseInt(fingerprint.substring(2, 4), 16)]);
    ret.push(emojiCodesArray[parseInt(fingerprint.substring(4, 6), 16)]);
    ret.push(emojiCodesArray[parseInt(fingerprint.substring(6, 8), 16)]);
    return ret;
  }

}
