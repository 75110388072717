export class Queue<T> {

  queue: T[] = [];
  currentElement: T | undefined = undefined;

  constructor(private f:(element: T, done:() => void) => void) {
    this.process();
  }

  push(element: T) {
    this.queue.push(element);
  }

  process() {
    try {
      if(this.currentElement == undefined ) {
        this.currentElement = this.queue.shift();
        if(this.currentElement != undefined) {
          // console.log(this.currentElement);
          try {
            this.f(this.currentElement, ()=> {
              this.currentElement = undefined;
              this.process();
            });
          } catch (e) {
            console.log(e);
            this.currentElement = undefined;
            this.process();
          }
        } else {
          setTimeout(()=> {this.process();}, 10);
        }
      } else {
        setTimeout(()=> {this.process();}, 10);
      }

    } catch (e) {
      setTimeout(()=> {this.process();}, 10);
      console.log(e);

    }
  }


}

