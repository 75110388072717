<h1 mat-dialog-title>Add participants</h1>

<div mat-dialog-content>




  <section>
    <span>


   <p *ngFor="let member of members">

        <mat-checkbox [(ngModel)]="member.checked" [color]="'primary'">
          <img src="{{member.avatarSrc}}" style="border-radius: 50%;" width="40" height="40">
    &nbsp;&nbsp;&nbsp;<span style="position: relative; top: -13px; ">{{member.nickOpen}} {{member.contactIdOpen}}</span>
        </mat-checkbox>
  </p>

  </span>
  </section>


</div>

<mat-dialog-actions align="end">
  <a [mat-dialog-close]="true" style="cursor: pointer ;color: #2e7d32;">CANCEL</a>
  &nbsp;&nbsp;&nbsp;
  <a (click)="add()"  style="cursor: pointer ;color: #2e7d32;">ADD</a>
</mat-dialog-actions>
