<h1 mat-dialog-title>Add image or file (document, archive and etc...)</h1>
<div mat-dialog-content>

  <div class="form-group">
    <label>Comment for entry</label>
    <input type="text" class="form-control" [formControl]="message"
           placeholder="Comment...">
  </div>

  <div class="form-group">
    <input type="file"
           id="file" multiple
           (change)="handleFileInput($event)">
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="upload()" [disabled]="files == null" cdkFocusInitial>Upload</button>
</div>

