import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { HomeComponent } from './home.component';
import {Routes, RouterModule} from '@angular/router';
import { RouterComponent } from './router.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RegistrationAppComponent } from './registration-app.component';
import { LoginComponent } from './login.component';
import { RegistrationUserComponent } from './registration-user.component';
import { CreateConversationComponent } from './create-conversation.component';
import { AutofocusDirective } from './autofocus.directive';
import { InformationComponent } from './information.component';
import { InviteComponent } from './invite.component';
import { ForgetComponent } from './forget.component';
import { ScrolldownDirective } from './scrolldown.directive';
import { SettingsComponent } from './settings.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { HttpClientModule } from '@angular/common/http';
import { UploadImageComponent } from './upload-image.component';
import { NavComponent } from './nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatSelectModule} from "@angular/material/select";

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatDialogModule} from '@angular/material/dialog';
import { VerificationComponent } from './verification.component';
import { InfoComponent } from './info.component';
import { FingerprintComponent } from './fingerprint.component';
import { WarningComponent } from './warning.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyfingerprintComponent } from './myfingerprint.component';
import { ViewFileComponent } from './view-file.component';
import { ContactsComponent } from './contacts.component';
import { AddParticipantComponent } from './add-participant.component';
import { SearchComponent } from './search.component';
import { EulaComponent } from './eula.component';
import { FaqComponent } from './faq.component';
import { DeleteComponent } from './delete.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'messenger', component: AppComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'registration-app', component: RegistrationAppComponent },
  { path: 'delete', component: DeleteComponent },
  { path: 'login', component: LoginComponent },
  { path: 'search', component: SearchComponent },
  { path: 'search/:uuid', component: SearchComponent },
  { path: 'forget', component: ForgetComponent },
  { path: 'registration', component: RegistrationUserComponent },
  { path: 'create-conversation', component: CreateConversationComponent },
  { path: 'information/:uuid', component: InformationComponent },
  { path: 'invite/:uuid', component: InviteComponent },
  { path: '**', redirectTo: '/'}
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RouterComponent,
    RegistrationAppComponent,
    LoginComponent,
    RegistrationUserComponent,
    CreateConversationComponent,
    AutofocusDirective,
    InformationComponent,
    InviteComponent,
    ForgetComponent,
    ScrolldownDirective,
    SettingsComponent,
    UploadImageComponent,
    NavComponent,
    VerificationComponent,
    InfoComponent,
    FingerprintComponent,
    WarningComponent,
    MyfingerprintComponent,
    ViewFileComponent,
    ContactsComponent,
    AddParticipantComponent,
    SearchComponent,
    EulaComponent,
    FaqComponent,
    DeleteComponent
  ],
  imports: [
    MatCheckboxModule,
    FormsModule,
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatBadgeModule,
    MatMenuModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [

  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standart' } },
  ],
  bootstrap: [RouterComponent],
  entryComponents: [UploadImageComponent],


})

//@ts-ignore
export class AppModule { }
