import { Component, OnInit } from '@angular/core';
import {Encryption} from "./encryption";
import {User} from "./model";
import {AddUserKeys, ConfirmUpdateUserKeys, GetUserInfo, UpdateUserKeys} from "./request";
import {StatusMessage, UserInfo} from "./response";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MessangerConnectionService} from "./messanger-connection.service";

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
})
export class ForgetComponent implements OnInit {


  forgetFocus = true;
  forget = false;
  passwordNew = new FormControl('',[Validators.required, Validators.minLength(8)]);
  passwordNewR =  new FormControl('',[Validators.required, Validators.minLength(8)]);
  showProgress = false;
  hideSmsCode = true;
  focusSmsCode = false;
  smsCode = new FormControl('', [Validators.required, Validators.minLength(3), additinalValidator(this)]);
  user: User = new User("", "", "", new Date(), new Date(), "", "");
  encryption: Encryption = {} as Encryption;
  smsCodeErrorHide= true;
  newPasswordForm: FormGroup;
  hide: boolean = true;
  smsConfirmation: FormGroup;

  constructor(private router: Router, public connection: MessangerConnectionService, fb: FormBuilder) {
    this.newPasswordForm = fb.group({
      passwordNew: this.passwordNew,
      passwordNewR: this.passwordNewR,
    });
    this.smsConfirmation = fb.group({
      smsCode: this.smsCode,
    });

  }




  ngOnInit() {

    if(this.connection.appUuid == null) {
      this.router.navigate(['/']);
    } else {
      this.connection.authorizeApp(() => {}, ()=>{
        this.router.navigate(['/']);
      });

    }
  }



  updateKeys() {
    this.showProgress = true;
    setTimeout(() => {
      this.updateKeysImpl();
    }, 1000);
  }

  updateKeysImpl() {
    this.encryption = new Encryption(this.connection);
    this.user = this.encryption.generateUserKeys(this.connection.userId, "", this.passwordNew.value);
    let requestUpdateUserKeys = new UpdateUserKeys(this.user.publicKeyBase64, this.user.privateKeyEncryptedBase64);
    this.connection.sendRequest(requestUpdateUserKeys,
      (responseAddUserKeys) => {
            this.hideSmsCode = false;
            this.showProgress = false;
            setTimeout(()=>{
              this.focusSmsCode = true;
            }, 500);
      });
  }

  confirmKeys(event: any) {
    if (event.keyCode == 13) {
      if(this.smsCode.valid) {
        this.confirm();
      }
    }
  }

  confirm() {

    let requestConfirmUpdateUserKeys = new ConfirmUpdateUserKeys(this.smsCode.value);
    this.connection.sendRequest(requestConfirmUpdateUserKeys, (responseStatus) => {
      if((responseStatus as StatusMessage).status == "ok") {
        this.connection.encryption = this.encryption;
        let requestGetUserInfo = new GetUserInfo(this.connection.userId);
        this.connection.sendRequest(requestGetUserInfo,
          (responseUserInfo) => {
              this.connection.user = (responseUserInfo as UserInfo).user;
              this.connection.user = this.user;
              this.router.navigate(['/messenger']);
          }
        );
        this.router.navigate(['/messenger']);
      } else {
        this.smsCodeErrorHide = false;
        this.smsCode.updateValueAndValidity();
        setTimeout(()=> {
          this.smsCodeErrorHide = true;
        }, 2000);
        setTimeout(()=>{this.focusSmsCode = true;},500)
      }
    });
  }

  formatPhone(str: String) {
    return this.connection.formatPhone(str);
  }
  updateKeysKey(event: any) {
    if (event.keyCode == 13) {
      if(this.passwordNew.valid && (this.passwordNew.value == this.passwordNewR.value)) {
        this.updateKeys();
      }
    }
  }

}

export function additinalValidator(trigger: ForgetComponent): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!trigger.smsCodeErrorHide) {
      return {additinal: {valid: false}} ;
    } else {
      return null;
    }
  };
}
