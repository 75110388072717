import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractContact} from "./contact";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Application, ApplicationFull, Contact, User} from './model';
import {Router} from "@angular/router";
import {MessangerConnectionService} from "./messanger-connection.service";
import {DeleteApplication, GetApplications, GetApplicationsFull, GetRegisteredUsers, UpdateContactList, UpdateUserNick} from './request';
import {Applications, ApplicationsFull, RegisteredUsers, StatusMessage} from './response';
import { faMinusCircle, faPaperPlane, faImages, faEdit, faPowerOff, faMale, faUserPlus, faKey, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import * as forge from 'node-forge'
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})



export class SettingsComponent  implements OnInit {
  faEdit = faEdit;
  faUserPlus = faUserPlus;
  faMinusCircle = faMinusCircle;
  formDialog = new FormGroup({
    userId: new FormControl()
  });


  user: User = new User("", "", "", new Date, new Date, "","");
  contactUserId = new FormControl('', [Validators.required, Validators.minLength(5),
    Validators.pattern("^[+][0-9]+$")]);

  nickForm: FormGroup = {} as FormGroup;
  nick = new FormControl('');

  nickChanged:boolean = false;
  currentAppUuid = null;
  applications: ApplicationFull[] = [];
  hideUserAbsent: boolean = true;
  files: FileList = {} as FileList;
  timeStamp = (new Date()).getTime();
  avatar: string = "";

  constructor(private router: Router, public con: MessangerConnectionService, private fb: FormBuilder) {
    if(this.con.user != null) {

      this.nickForm = new FormGroup({
        nick: this.nick
      });
      this.user = this.con.user;
      this.currentAppUuid = this.con.appUuid;
      this.nick.setValue(this.con.nickOpen);

      this.reloadApplications();
      this.downloadAvatar();
      // this.timeOutCycle();

    }
  }

  // timeOutCycle() {
  //   let that = this;
  //   setTimeout(function () {
  //     that.reloadApplications();
  //     that.timeOutCycle();
  //   }, 2000);
  // }

  getWebsocket(): MessangerConnectionService {
    return this.con;
  }


  ngOnInit() {

    if (this.con.encryption == null) {
      this.router.navigate(['/']);
    } else {

    }
  }



  handleFileInput(event: Event) {
    const target= event.target as HTMLInputElement;
    this.files = (target.files as FileList)
    this.upload();

  }

  downloadAvatar() {
    this.con.downloadAvatar((avatar)=> {
      this.avatar = avatar;
    })
  }


  // @ts-ignore
  @ViewChild('clickUpload') clickUpload: ElementRef;

  upload() {
    if(this.files.length == undefined) {
      this.clickUpload.nativeElement.click();
      return;
    }
    let files: File[] = [];
    for(let i=0; i < this.files.length; i++) {
      let name = (this.files.item(i) as File).name.split(".")[0];

      let ext = (this.files.item(i) as File).name.split(".")[1];
      if(ext.toLowerCase() == "jpg" || ext.toLowerCase() == "jpeg") {
        let file = (this.files.item(i) as File)
        let f = new File([file.slice()],name + '.' + ext.toLowerCase());
        files.push(f);
      } else {
        alert("Accept only JPEG format for avatar")
        this.files = {} as FileList;
        return;
      }
    }



    this.con.uploadAvatar(files[0], () => {

      setTimeout(() => {this.downloadAvatar();}, 2000);


      this.files = {} as FileList;
    });
  }







  updateNickSave() {
    let updateNick = new UpdateUserNick(this.con.encryption.encryptByMetaDataKey(this.nick.value));
    this.con.sendRequest(updateNick, (status) => {
      this.user.nick = this.con.encryption.encryptByMetaDataKey(this.nick.value);
      this.con.nickOpen = this.nick.value;
      this.nickChanged = false;
    });
  }


  deleteApplication(application: Application) {
      let deleteApp = new DeleteApplication(application.uuid);
      this.con.sendRequest(deleteApp, (status) => {
        this.reloadApplications();
      })
  }

  private reloadApplications() {
    let getApplications = new GetApplicationsFull(true);
    this.con.sendRequest(getApplications, (applications) => {
      this.applications = (applications as ApplicationsFull).applications.sort((a, b) => {
        if (a.created.getTime() < b.created.getTime()) {
          return 1;
        }
        if (a.created.getTime() > b.created.getTime()) {
          return -1;
        }
        if (a.created.getTime() == b.created.getTime()) {
          return 0;
        }
        return 0;
      })
    })

  }

}
