<h1 mat-dialog-title></h1>
<div mat-dialog-content align="center">

  <img src="/download/avatars/user-profile.jpeg" width="200" height="200">

  <br>

<!--  {{data.nickOpen}}-->

  <br>

<!--  {{data.contactIdOpen}}-->


</div>
<div mat-dialog-actions  align="center">
  <button mat-button mat-dialog-close cdkFocusInitial>Verify</button>
</div>
