import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Request, AuthenticationByApplication, GetUserInfo, AddUserKeys, UpdateUserNick, Ping} from './request';
import { Response, StatusMessage, UserInfo } from './response';
import { Encryption } from './encryption';
import { User } from './model';
import { MessangerConnectionService } from './messanger-connection.service';
import { Router } from '@angular/router';
import {VerificationComponent} from "./verification.component";
import {EulaComponent} from "./eula.component";
import {MatDialog} from "@angular/material/dialog";




@Component({
  selector: 'app-registration-user',
  templateUrl: './registration-user.component.html',
  styleUrls: ['./registration-user.component.css']
})
export class RegistrationUserComponent implements OnInit {

  userRegistration: FormGroup;
  password = new FormControl('', [Validators.required, Validators.minLength(8)]);
  passwordR = new FormControl('', [Validators.required, Validators.minLength(8)]);
  nick = new FormControl('', [Validators.required, Validators.minLength(3)]);
  showProgress = false;
  hide: boolean = true;
  eula = new FormControl(false);

  okReloadAndBack = false;

  constructor(private router: Router, public con: MessangerConnectionService, public dialog: MatDialog, fb: FormBuilder) {
    let that = this
    window.addEventListener("beforeunload", function (e) {
      if (that.okReloadAndBack) {
        return undefined;
      }
      var confirmationMessage = 'If you leave before saving, your changes will be lost.';

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });

    this.userRegistration = fb.group({
      passwordR: this.passwordR,
      password: this.password,
      nick: this.nick,
    });

    setTimeout(()=> {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000)
  }

  registration() {

    let ping = new Ping(new Date());
    this.con.sendRequest(ping, (status) => {
      this.showProgress = true;
      setTimeout(() => {
        this.registrationImpl();
      }, 3000);
    });
  }
  registrationImpl() {
    let encryption = new Encryption(this.con);
    let user: User = encryption.generateUserKeys(this.con.userId, this.nick.value, this.password.value);
    let requestAddUserKeys = new AddUserKeys(user.publicKeyBase64, user.privateKeyEncryptedBase64, user.metaDataKeyEncryptedBase64, encryption.encryptByMetaDataKey(""));
    this.con.sendRequest(requestAddUserKeys,
      (responseAddUserKeys) => {
        this.con.encryption = encryption;
        this.con.user = user;
        let updateNick = new UpdateUserNick(this.con.encryption.encryptByMetaDataKey(this.nick.value));
        this.con.sendRequest(updateNick, (status) => {
          this.con.nickOpen = this.nick.value;
          this.router.navigate(['/messenger']);
          this.okReloadAndBack = true;
        });
      });


  }

  ngOnInit() {
    if (this.con.appUuid == null) {
      this.router.navigate(['/']);
    } else {
      this.con.authorizeApp(() => { }, () => {
        this.router.navigate(['/']);
      });

    }
  }

  registrationKeys(event: any) {
    if (event.keyCode == 13) {
      if(this.nick.valid && this.password.valid && (this.password.value == this.passwordR.value)) {
        this.registration();
      }
    }
  }

  back() {
    this.con.clear();
    window.location.href = "/";
  }

  eulaShow() {
    const eulaComponent = this.dialog.open(EulaComponent, {
      width: '600px',

    });


  }
}
