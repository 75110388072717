<div style="overflow-y: auto;">
  <mat-selection-list [multiple]="false">
    <mat-list-option *ngFor="let contact of contacts" (click)="showFingerprint(contact)">
      <img mat-list-avatar [src]="contact.avatarSrc"  style="border-radius: 50%;" width="100" height="100">
      <div mat-line><span>{{contact.nickOpen}}</span></div>
    </mat-list-option>
  </mat-selection-list>
  <br>
  <button (click)="addContact()" mat-flat-button mat-button color="primary"
          style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};"  [hidden]="addContactShow">Add contact</button>


  <form [formGroup]="contactForm" [hidden]="!addContactShow">
    <mat-form-field appearance="standard" style="padding: 20px 0px 0px 20px">
      <mat-label>Phone</mat-label>
      <input matInput #input [autofocus]="true" [formControl]="phone"  (keydown)="change()" >
      <mat-hint *ngIf="showHint">Format: +NNNNNNNNN<br>Example: +19133869143</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="standard" style="padding: 20px 0px 0px 20px">
      <mat-label>Nickname</mat-label>
      <input maxlength="128"  matInput #input  [formControl]="nick">
    </mat-form-field>
    <button (click)="addContactSave()" mat-flat-button mat-button color="primary"  style="color:{{con.theme.buttonText}}; background-color: {{con.theme.buttonBackground}};" [disabled]="showHint" >Save</button>
  </form>


</div>
